/*COMMING SOON*/
.uni-comming-soon{
  display:  flex;
  align-items: center;
  justify-content: center;
  background-image: url("../../images/comming-soon/comming-soon21.jpg");
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media screen and (max-width: 991px) {
    height: auto;
  }

  .uni-comming-soon-left{
    background-color: rgba(0, 0, 0, 0.7);
    padding:  60px 40px;
    @media screen and (max-width: 1199px) and (min-width: 992px) {
      padding:  60px 20px;
    }
    @media screen and (max-width: 991px) {
      padding: 20px;    margin-top: 60px;
    }
    .comming-soon-logo{
      img{
        margin:  0 auto;
      }
    }
    h1{
      font-family: "Proxima Nova Th";
      font-size: 48px;
      font-weight: bold;
      font-style: italic;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      text-transform:  uppercase;
      color: #e57632;
      margin:  60px 0;
      @media screen and (max-width: 1199px) and (min-width: 992px) {
        font-size: 36px;
        margin: 40px 0;
      }
      span{
        color: #fff;
      }
    }
    p{
      font-family: "Proxima Nova Th";
      font-size: 16px;
      font-weight: bold;
      font-style: italic;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 2.6px;
      text-align: center;
      text-transform:  uppercase;
      color: rgba(255, 255, 255, 0.5);
      margin-bottom:  30px;
    }
    .input-group{
      width: 100%;
      input{
        width: 60%;
        height: 50px;
        border-radius: 4px;
        background-color: transparent;
        font-family: "Open Sans";
        font-size: 16px;
        border: solid 1px #ffffff;
        color: #fff;
        font-style: italic;
        @media screen and (max-width: 375px) {
          width: 100%; 
        } 
        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          font-family: "Open Sans";
          font-size: 16px;
          font-weight: normal;
          font-style: italic;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: rgba(255, 255, 255, 0.3);
        }
        &::-moz-placeholder { /* Firefox 19+ */
          font-family: "Open Sans";
          font-size: 16px;
          font-weight: normal;
          font-style: italic;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: rgba(255, 255, 255, 0.3);
        }
        &:-ms-input-placeholder { /* IE 10+ */
          font-family: "Open Sans";
          font-size: 16px;
          font-weight: normal;
          font-style: italic;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: rgba(255, 255, 255, 0.3);
        }
        &:-moz-placeholder { /* Firefox 18- */
          font-family: "Open Sans";
          font-size: 16px;
          font-weight: normal;
          font-style: italic;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: rgba(255, 255, 255, 0.3);
        }
      }
      button{
        width: 37%;
        height: 50px;
        border-radius: 4px;
        background-color: #ffffff;
        border:  1px solid #fff;
        font-family: "Proxima Nova Th";
        font-size: 14px;
        font-weight: bold;
        font-style: italic;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        text-transform:  uppercase;
        color: #22292d;
        transition:  all 0.5s;
        margin-left: 10px;
        @media screen and (max-width: 767px) {
          width: 35%;
        }    
        @media screen and (max-width: 375px) {
          width: 100%; margin:15px auto 0 auto;
        }    
        &:hover{
          color: #fff;
          background: #e57632;
          border-color: #e57632;
        }
      }
    }
    .uni-social{
      padding-top: 60px;
      text-align: center;
      ul{
        li{
          display: inline-block;
          margin-right: 5px;
          a{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 44px;
            height: 44px;
            background-color: #343c40;
            font-family: FontAwesome;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.86;
            letter-spacing: normal;
            text-align: center;
            color: #aeaeae;
            transition:  all 0.5s;
            &:hover{
              background: #e57632;
              color: #fff;
            }
          }
        }
      }
    }
  }

  .uni-comming-soon-right{
    min-height: 566px;
    padding-left: 70px;
    display: flex;
    align-items: center;
    @media screen and (max-width: 991px) {
      padding-left: 0;
    }
    .elem-center{
      .days{
        font-family: "Open Sans";
        font-size: 160px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;
      }
      .txt{
        font-family: "Open Sans";
        font-size: 24px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        text-transform:  uppercase;
        color: #ffffff;
        position: relative;
        top: -100px;
        @media screen and (max-width: 767px) {
          top: 0;
        }
      }
    }
    .elem-bottom{
      .elem-number{
        font-family: "Open Sans";
        font-size: 36px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;
      }
      .elem-txt{
        font-family: "Open Sans";
        font-size: 18px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;
      }
      .spaces{
        font-family: "Open Sans";
        font-size: 18px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;
        padding:  0 15px;
      }
    }
  }
}