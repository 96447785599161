/*CHECK OUT*/
.uni-checkout{
  .uni-checkout-body{
    background-color: #f4f4f4;
    padding: 100px 0;
    @media screen and (max-width: 767px){
      padding: 30px 0 0 0;
    }
    .checkout-ship-address-checkbox{
      display: none;
    }
    .checkbox-create-account-form{
      display: none;
    }
    .woocommerce-form-login{
      p{
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        line-height: 1.57;
        text-align: left;
        color: #7b7b7b;
        padding-bottom: 20px;
      }
    }
    .vk-form-woo-login{
      display: none;
      background: #ffffff;
      margin-top: 10px;
      margin-bottom: 10px;
      padding: 30px 20px;
      border-radius: 3px;
      border: 1px solid #e0e0e0;
    }
    .vk-checkout-ship-address{
      select{
        width: 100%;
        height: 50px;
        background-color: #ffffff;
        border: 1px solid #e0e0e0;
        padding: 0 20px;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        line-height: 0;
        text-align: left;
        color: #aeaeae;
      }
      textarea{
        width: 100%;
        height: 151px;
        background-color: #ffffff;
        border: 1px solid #f0f0f0;
        padding: 20px;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        text-align: left;
        color: #aeaeae;
      }
    }
    .vk-checkout-creat-account{
      h3{
        text-transform: none;
        label{
          font-family: "Open Sans";
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.57;
          letter-spacing: normal;
          text-align: left;
          text-transform: capitalize;
          color: #22292d;
        }
      }
      .vk-check-create-account-form{
        .form-row{
          margin: 30px 0;
        }
      }
    }

    #payment{
      padding-top: 60px;
      h3{
        font-family: 'Proxima Nova Th';
        font-size: 18px;
        font-weight: bold;
        text-align: left;
        color: #333333;
        margin-bottom: 0;
      }
      ul{
        margin: 0;
        padding: 15px 0;
        li{
          list-style-type: none;
          label{
            font-family: "Open Sans";
            font-size: 14px;
            line-height: 2.57;
            text-align: left;
            font-weight: normal;
            color: #22292d;
          }
          .payment_box{
            padding-left: 20px;
            p{
              font-family: 'Open Sans', sans-serif;
              font-size: 14px;
              line-height: 1.86;
              text-align: left;
              color: #7b7b7b;
            }
          }
          a{
            font-family: 'Proxima Nova Th';
            font-size: 14px;
            line-height: 2.57;
            text-align: left;
            color: #e57632;
            margin-left: 20px;
            &:hover{
              color: #333333;
            }
          }

        }
      }
      .place-order{
        margin-top: 30px;
        input{
          width: 100%;
          height: 50px;
          background-color: #e57632;
          border: 1px solid #e57632;
          font-family:'Open Sans', sans-serif;
          font-size: 14px;
          font-weight: bold;
          line-height: 2.77;
          text-align: center;
          color: #ffffff;
          transition: all 0.5s;
          &:hover {
            background: #fff;
            color: #e57632;
            transition: all 0.5s;
          }
        }
      }
    }

    .vk-checkout-order-left{
      h3{
        font-family: 'Proxima Nova Th';
        font-size: 18px;
        font-weight: bold;
        text-align: left;
        color: #333333;
        text-transform: uppercase;
        margin-bottom: 25px;
      }
      table{
        width: 100%;
        thead{
          tr{
            th{
              font-size: 16px;
              font-weight: bold;
              line-height: 2.14;
              text-align: left;
              color: #404040;
              text-transform: uppercase;
            }
          }
        }
        tbody{
          tr{
            td{
              font-family: "Open Sans";
              font-size: 14px;
              line-height: 2.14;
              text-align: left;
              color: #666666;
            }
          }
        }
        tfoot{
          tr{
            th{
              font-family: 'Proxima Nova Th';
              font-size: 14px;
              font-weight: 500;
              line-height: 2.14;
              text-align: left;
              color: #404040;
            }
          }
        }
        tr{
          td{
            border-bottom: 1px solid #e0e0e0;
            padding:  15px 0;

          }
          th{
            border-bottom: 1px solid #e0e0e0;
            padding:  15px 0;
          }
        }
      }
      thead{
        border-bottom: 2px solid #e57632;
      }
      .order-total{
        color: #404040;
      }
    }
    .vk-checkout-billing-left{
      padding-top: 60px;
      padding-bottom: 43px;
      h3{
        font-family: 'Proxima Nova Th';
        font-size: 18px;
        font-weight: bold;
        text-align: left;
        color: #333333;
        margin-bottom: 20px;
        text-transform: uppercase;
      }
      p{
        margin-bottom: 27px;
        label{
          font-family: "Open Sans";
          font-size: 14px;
          font-weight: normal;
          line-height: 1.57;
          text-align: left;
          color: #22292d;
          margin-bottom: 10px;
        }
        .required{
          font-family: 'Proxima Nova Th';
          font-size: 18px;
          font-weight: 900;
          line-height: 1.22;
          color: #e57632;
        }
      }
      .woocommerce-billing-fields{
        select{
          width: 100%;
          height: 50px;
          background-color: #ffffff;
          border: 1px solid #e0e0e0;
          padding: 0 20px;
          font-family: "Open Sans";
          font-size: 14px;
          line-height: 0;
          text-align: left;
          color: #aeaeae;
          border-radius: 3px;
        }
      }
    }
    .vk-check-coupon{
      display: none;
      background: #ffffff;
      padding: 20px;
      border: 1px solid #e0e0e0;
      margin-top: 10px;
      .form-row-first{
        input{
          width: 100%;
          height: 40px;
        }
      }
      p{
        margin-bottom: 0;
      }
      .form-row-last{
        input[type="submit"]{
          border: 1px solid #e57632;
          font-family: 'Proxima Nova Th';
          font-size: 13px;
          font-weight: bold;
          line-height: 0;
          text-align: center;
          color: #ffffff;
          background: #e57632;
          transition: all 0.5s;
          &:hover{
            background: #fff;
            color: #e57632;
            transition: all 0.5s;
          }
        }

      }
    }
    .entry-content-wrapper{
      .woocommerce-info{
        margin-bottom: 10px;
        background: #fff;
        padding: 14px 20px;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        line-height: 2.14;
        text-align: left;
        color: #666;
        border: 1px solid #e0e0e0;
        border-top: 2px solid #e57632;
        border-radius: 3px;
        i{
          font-family: FontAwesome;
          font-size: 18px;
          line-height: 1.67;
          color: #e57632;
          margin-right: 20px;
        }
        a{
          color: #e57632;
          transition:  all 0.5s;
          &:hover{
            color: #22292d;
          }
        }
      }
      .form-row-first{
        input{
          width: 100%;
          height: 40px;
          background-color: #ffffff;
          border: 1px solid  #e0e0e0;
          padding: 20px;
          font-family: 'Open Sans', sans-serif;
          font-size: 14px;
          line-height: 1.57;
          text-align: left;
          color: #e0e0e0;
          border-radius: 3px;
        }
      }
      .form-row-last{
        input{
          width: 100%;
          height: 40px;
          background-color: #ffffff;
          border: 1px solid  #e0e0e0;
          padding: 20px;
          font-family: 'Open Sans', sans-serif;
          font-size: 14px;
          line-height: 1.57;
          text-align: left;
          color: #aeaeae;
          border-radius: 3px;
        }
        textarea{
          width: 100%;
          height: 120px;
          border: 1px solid  #e0e0e0;
          border-radius: 3px;
        }
      }
      .vk-checkout-login{
        margin-top: 30px;
        p{
          margin-bottom: 0px;
        }
        input{
          &.button{
            width: 120px;
            height: 40px;
            background-color: #e57632;
            border-radius: 3px;
          }
        }
        input[type="submit"]{
          border: 1px solid #e57632;
          font-family: 'Open Sans', sans-serif;
          font-size: 14px;
          font-weight: bold;
          line-height: 1.69;
          text-align: center;
          color: #ffffff;
          transition: all 0.5s;
          &:hover{
            background: #fff;
            color: #e57632;
            transition: all 0.5s;
          }
        }
        .woocommerce-form__label-for-checkbox{
          input[type=checkbox]{
            width: 16px;
            height: 16px;
            border-radius: 3px;
            background-color: #ffffff;
            border: solid 1px #aeaeae;
            margin-left: 20px;
          }
          span{
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            line-height: 1.57;
            text-align: left;
            color: #7b7b7b;
            font-weight: normal;
          }
        }
        .lost_password{
          margin: 0;
          padding-top: 15px;
          a{
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            line-height: 1.57;
            text-align: right;
            color: #e57632;
            &:hover{
              color: #333;
            }
            i{
              font-family: FontAwesome;
              font-size: 18px;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}