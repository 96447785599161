/*SEARCH POST*/
.uni-search-post{
  .item{
    @media screen and (max-width: 991px) {
      margin-bottom: 20px;
    }
    .item-img{
      overflow: hidden;
      img{
        width: 100%;
        height: auto;
        transition: all 0.5s;
        &:hover{
          transform: scale(1.1);
        }
      }
    }
    .item-info{
      padding: 20px 0;
      h3{
        a{
          font-family: "Proxima Nova Th";
          font-size: 18px;
          font-weight: bold;
          font-style: italic;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #22292d;
          display:  block;
          transition: all 0.5s;
          text-transform:  uppercase;
          &:hover{
            color: #e57632;
          }
        }
      }
      .uni-divider{
        border-color: #e57632;
      }
      ul{
        display:  flex;
        padding-top: 20px;
        li{
          display: inline-block;
          font-family: "Open Sans";
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #666666;
          margin-right: 15px;
          i{
            color: #e57632;
            padding-right: 5px;
          }
        }
      }
    }
  }
}