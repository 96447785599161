/*CONTACT*/
.uni-contact-us-body{
  padding:  60px 0;
  h2{
    font-family: "Proxima Nova Th";
    font-size: 36px;
    font-weight: bold;
    font-style: italic;
    font-stretch: normal;
    line-height: 32px;
    letter-spacing: normal;
    text-align: left;
    color: #22292d;
    text-transform:  uppercase;
    padding:  0 30px;
    span{
      color: #e57632;
    }
  }
}

.uni-contact-us-getintouch{
  background: #f4f4f4;
  padding:  30px 0;
  form{
    padding: 30px 30px 0 30px;
  }
  label{
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.14;
    letter-spacing: normal;
    text-align: left;
    color: #22292d;
    text-transform: uppercase;
    sup{
      color: #e57632;
    }
  }
  .form-control{
    border-radius: 0;
  }
  input{
    height: 44px;
  }
  .btn{
    margin-top: 20px;
    min-width: 140px;
    height: 44px;
    border-radius: 4px;
    background-color: #e57632;
    border:  1px solid #e57632;
    font-family: "Proxima Nova Th";
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: center;
    text-transform:  uppercase;
    color: #ffffff;
    transition:  all 0.5s;
    &:hover{
      color: #e57632;
      background:  transparent;
    }
  }
}

.uni-contact-us-contact-info{
  background: #f4f4f4;
  padding:  30px 0;
  ul{
    padding: 40px 30px;
    padding-bottom:  0;
    li{
      margin-bottom: 18px;
      .icons{
        font-family: FontAwesome;
        font-size: 36px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        text-align: center;
        color: #e57632;
        float:  left;
        margin-right: 10px;
      }
      .text{
        padding-left: 45px;
        span{
          font-family: "Proxima Nova Th";
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          letter-spacing: normal;
          text-align: left;
          font-size: 12px;
          line-height: 2;
          text-transform: uppercase;
          color: #e57632;
        }
        p{
          line-height: 1.71;
          text-transform: uppercase;
          margin-bottom:  0;
        }
      }
    }
  }
}

.uni-contact-us-map{
  margin-top: 60px;
  iframe{
    width: 100%;
    height: 600px;
    overflow: hidden;
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
  }
}