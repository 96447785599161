.uni-shortcode-testimonial{
  .uni-shortcode-testimonial-body{
    padding: 80px 0;
  }
}
.uni-testimonial-default{
  padding-bottom: 30px;
  .item-testimonial{
    border-radius: 4px;
    border: solid 1px #e57632;
    padding: 20px 30px;
    .testimonial_para{
      font-family: 'Open Sans', sans-serif;
      font-size: 16px;
      font-weight: normal;
      font-style: italic;
      font-stretch: normal;
      line-height: 1.63;
      letter-spacing: normal;
      text-align: left;
      color: #666666;
    }
    .col-sm-10.clear-padding{
      @media screen and (max-width: 575px){
        padding-left: 15px;
      }
    }
    h4{
      font-family: 'Proxima Nova Th';
      font-size: 14px;
      font-weight: bold;
      font-style: italic;
      font-stretch: normal;
      line-height: 1.57;
      letter-spacing: normal;
      text-align: left;
      text-transform: uppercase;
      color: #22292d;
      margin-bottom: 0;
    }
    .item-img{
      width: 60px;
      overflow: hidden;
      border-radius: 50%;
    }
    .item-info{
      padding-top: 7px;
    }
    .testimonial_subtitle{
      span{
        font-family: 'Proxima Nova Th';
        font-size: 14px;
        font-weight: bold;
        font-style: italic;
        font-stretch: normal;
        line-height: 1.57;
        letter-spacing: normal;
        text-align: left;
        text-transform: uppercase;
        color: #e57632;
      }
    }
  }
}