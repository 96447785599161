.uni-shortcode-tabs{
  .uni-shortcode-tabs-body{
    padding: 80px 0;
    /* Default mode */
  }
}

.uni-shortcode-tabs-default{
  .tabbable-line > .nav-tabs {
    border: none;
    margin: 0px;
  }
  .tabbable-line > .nav-tabs > li {
    margin-right: 2px;
  }
  .tabbable-line > .nav-tabs > li > a {
    font-family: 'Proxima Nova Th';
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.14;
    letter-spacing: normal;
    text-align: left;
    color: #aeaeae;
    text-transform: uppercase;
    border: 0;
    margin-right: 0;
    @media screen and (max-width: 767px) {
      font-size: 10px;
    }
  }
  .nav-tabs>li{
    margin-bottom: 0;
  }
  .tabbable-line > .nav-tabs > li > a > i {
    color: #a6a6a6;
  }
  .tabbable-line > .nav-tabs > li.open, .tabbable-line > .nav-tabs > li:hover {
    border-bottom: 2px solid #e57632;
  }
  .tabbable-line > .nav-tabs > li.open > a, .tabbable-line > .nav-tabs > li:hover > a {
    border: 0;
    background: none !important;
    color: #e57632;
  }
  .tabbable-line > .nav-tabs > li.open > a > i, .tabbable-line > .nav-tabs > li:hover > a > i {
    color: #a6a6a6;
  }
  .tabbable-line > .nav-tabs > li.open .dropdown-menu, .tabbable-line > .nav-tabs > li:hover .dropdown-menu {
    margin-top: 0px;
  }
  .tabbable-line > .nav-tabs > li.active {
    border-bottom: 2px solid #e57632;
    position: relative;
  }
  .tabbable-line > .nav-tabs > li.active > a {
    border: 0;
    color: #e57632;
  }
  .tabbable-line > .nav-tabs > li.active > a > i {
    color: #404040;
  }
  .tabbable-line > .tab-content {
    background-color: #fff;
    border: 0;
    border-top: 1px solid #eee;
    padding: 15px 0;
  }
  .portlet .tabbable-line > .tab-content {
    padding-bottom: 0;
  }
  .tab-content{
    p{
      font-family: "Open Sans";
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.86;
      letter-spacing: normal;
      text-align: left;
      color: #666666;
    }
  }
}

.uni-shortcode-tab-1{
  padding-bottom: 40px;
  .tabbable-line > .nav-tabs{
    background-color: #22292d;
  }
  .tabbable-line > .nav-tabs > li > a{
    line-height: 3;
  }
  .nav-tabs>li.active>a{
    background: transparent;
  }
  .tabbable-line > .nav-tabs > li.active {
    border-bottom: none;
    position: relative;
  }
  .tabbable-line > .nav-tabs > li.open, .tabbable-line > .nav-tabs > li:hover {
    border-bottom: none;
  }
  .tabbable-line > .nav-tabs > li.active > a {
    border: 0;
    color: #fff;
    background: #e57632;
    border-radius: 0;
  }
  .tabbable-line > .nav-tabs > li.open > a, .tabbable-line > .nav-tabs > li:hover > a {
    border: 0;
    background: #e57632 !important;
    color: #fff;
    border-radius: 0;
  }
  .nav>li>a{
    padding: 10px 34px;
  }
  .nav-tabs>li{
    margin-bottom: 0;
    padding-left: 5px;
  }
  .tabbable-line > .nav-tabs > li {
    margin-right: 0px;
  }
}