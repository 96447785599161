/*DIVIDER*/
.uni-banner-default{
  display: flex;
  align-items: center;
  justify-content:center;
  width: 100%;
  height: 240px;
  background-position: center center;
  background-size: cover;
  .page-title{
    float: left;
    h1{
      font-family: 'Proxima Nova Rg';
      font-weight: bold;
      font-style: italic;
      font-size: 48px;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #ffffff;
      text-transform: uppercase;
      span{
        color: #e57632;
      }
    }
  }
  .breadcrumbs{
    float: right;
    @media screen and (max-width: 767px) {
      float: none;
    }
    li{
      display: inline-block;
      text-transform: capitalize;
      a{
        font-family: "Open Sans";
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.86;
        letter-spacing: normal;
        text-align: right;
        color: #ffffff;
      }
      &:first-child{
        &:before{
          content: '';
        }
        a{
          color: #e57632;
        }
      }
      &:before{
        content: '.';
        font-size: 20px;
        color: #e57632;
        padding: 0 10px;
      }
    }
  }
}


.uni-shortcode-divider{
  .uni-shortcode-divider-body{
    padding-top: 100px;
  }
  .uni-divider{
    margin-top: 30px;
    margin-bottom: 100px;
  }
  h3{
    font-family: 'Proxima Nova Th';
    font-size: 30px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #22292d;
  }
}
.uni-divider {
  width: 100%;
  border-top-width: 1px;
  border-bottom-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  border-style: solid;
  border-color: #E0E0E0;
  overflow: hidden;
}

.uni-divider-double {
  height: 3px;
  border-bottom-width: 1px;
}

.uni-dot{
  border-style: dashed;
}

.uni-divider-2px-double{
  height: 3px;
  border-bottom-width: 3px;
}