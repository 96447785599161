/*SELL A CAR*/
.uni-sell-a-car-1{
  overflow: hidden;
}
.uni-sell-a-car-1-body{
  padding:  20px 0;
}
.uni-sell-a-car-1-left{
  background-color: #22292d;
  padding: 40px 30px;
  h3{
    font-family: "Proxima Nova Th";
    font-size: 30px;
    font-weight: bold;
    font-style: italic;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #e57632;
    text-transform:  uppercase;
    span{
      color: #fff;
    }
  }
  .uni-divider{
    border-color: #e57632;
  }
  p{
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.86;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    padding:  30px 0;
    margin-bottom: 0;
  }
  ul{
    li{
      padding-bottom: 20px;
      &:last-child{
        padding-bottom:  0;
      }
      .icon{
        font-family: FontAwesome;
        font-size: 36px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 0.83;
        letter-spacing: normal;
        color: #e57632;
        float:  left;
        margin-right: 10px;
      }
      .text{
        min-height:  40px;
        padding-left: 50px;
        span{
          font-family: "Proxima Nova Th";
          font-size: 12px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          color: #e57632;
          text-transform:  uppercase;
        }
        p{
          font-family: "Proxima Nova Th";
          line-height: 1.14;
          text-align: left;
          color: #ebeff0;
          text-transform:  uppercase;
          padding: 0;
        }
      }
    }
  }
}

.uni-sell-a-car-1-right{
  .uni-shortcode-tab-2{
    background: #f4f4f4;
  }
  .uni-shortcode-tabs-default .tabbable-line > .tab-content{
    background:  transparent;
    height: 36em;
  }
  .nav-tabs>li.active>a{
    background:  transparent;
  }
  .uni-shortcode-tabs-default .tabbable-line > .nav-tabs{
    padding-left: 20px;
  }
  .nav>li>a{
    padding: 10px 20px;
  }
  button{
    display:  block;
    width: 100%;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 50px;
    letter-spacing: normal;
    text-align: left;
    color: #22292d;
    border-radius: 4px;
    border: solid 1px #e57632;
    background:  transparent;
    text-transform:  uppercase;
    transition:  all 0.5s;
    padding: 0 20px;
    &.trade{
      margin-bottom: 10px;
    }
    &:hover{
      color: #fff;
      background: #e57632;
      i{
        color: #fff;
      }
    }
    i{
      font-size: 18px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: left;
      color: #e57632;
      padding-right: 10px;
    }
  }
  label{
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.14;
    letter-spacing: normal;
    text-align: left;
    text-transform:  uppercase;
    color: #22292d;
    sup{
      color: #e57632;
      font-weight: bold;
    }
  }
  label.error{
    color:red;
  }
  .form-control{
    border-radius: 4px;
    background-color: #ffffff;
    border: solid 1px #e0e0e0;
  }
  // input{
  //   height: 44px;
  // }
  textarea{
    height: 140px;
  }
  .nav-tabs{
    li{
      a{
        font-style: italic;
      }
    }
  }
  .tab-content{
    background:  transparent;
    .contact-detail{
      padding:  0 20px;
      button{
        margin-left: 0;
      }
    }
    .uni-upload-img{
      padding:  0 20px;
      h4{
        font-family: "Open Sans";
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 2.14;
        letter-spacing: normal;
        text-align: left;
        text-transform:  uppercase;
        color: #22292d
      }
      ul{
        li{
          display:  inline-block;
          margin-right: 5px;
          width: 115px;
          height: 80px;
          overflow: hidden;
          position: relative;
          &:last-child{
            border-radius: 4px;
            background-color: #e0e0e0;
            font-family: "Open Sans";
            font-size: 30px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: 80px;
            letter-spacing: normal;
            text-align: center;
            color: #aeaeae;
            &:hover{
              cursor: pointer;
            }
          }

          .uni-upload-de-fix{
            position: absolute;
            right: 3px;
            bottom: 3px;
            span{
              display:  block;
              float: left;
              margin-left: 10px;
              width: 18px;
              height: 18px;
              border-radius: 2px;
              background-color: rgba(0, 0, 0, 0.6);
              font-family: FontAwesome;
              font-size: 12px;
              font-weight: normal;
              font-style: normal;
              font-stretch: normal;
              line-height: 2.5;
              letter-spacing: normal;
              text-align: center;
              color: #ffffff;
              transition:  all 0.5s;
              &:hover{
                color: #e57632;
                cursor: pointer;
              }
            }
            .fix{
              display: flex;
              align-items: center;
              justify-content: center;

            }
            .delete{
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }
    .uni-paste-a-link{
      padding: 0 20px;
      margin-bottom: 30px;
      margin-top: 20px;
    }
    button {
      display: block;
      width: auto;
      font-family: "Open Sans";
      font-size: 14px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 42px;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
      border-radius: 4px;
      border: solid 1px #e57632;
      background: #e57632;
      text-transform: uppercase;
      transition: all 0.5s;
      padding: 0 20px;
      margin:  0 20px;
      &:hover{
        background:  #fff;
        color: #e57632;
      }
    }
  }
  .uni-show-notification{
    padding-top: 30px;
    display:  none;
    p{
      font-family: "Open Sans";
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.86;
      letter-spacing: normal;
      text-align: left;
      color: #666666;
    }
  }
}