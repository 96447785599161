
/*BLOG LIST*/
.uni-blog-list-body{
  padding: 80px 0;
}
.blog-list-item{
  padding-bottom: 60px;
  .uni-entry-top{
    .thumbnail-img{
      overflow: hidden;
      position: relative;
      img{
        width: 100%;
        height: auto;
        transition:  all 0.5s;
        &:hover{
          transform: scale(1.1);
        }
      }
      .blog-time-caption{
        position:  absolute;
        z-index: 3;
        bottom: 20px;
        left: 20px;
        padding-left: 20px;
        background: #fff;
        min-height: 44px;
        min-width: 150px;
        color:white;
        &:before{
          background: #fff none repeat scroll 0 0;
          content: "";
          min-height: 44px;
          position: absolute;
          right: -25px;
          transform: skew(-35deg);
          width: 50px;
          border-right: 5px solid #e57632;
        }
        span{
          position: relative;
          font-family: 'proxima_nova_altbold';
          font-size: 18px;
          font-weight: bold;
          font-style: italic;
          font-stretch: normal;
          letter-spacing: normal;
          text-align: center;
          color: #e57632;
          line-height: 44px;
          z-index: 5;
        }
      }
    }
  }
  .uni-entry-content{
    padding-top: 30px;
    .uni-entry-title{
      a{
        display:  block;
        font-family: 'Proxima Nova Th';
        font-size: 24px;
        font-weight: bold;
        font-style: italic;
        font-stretch: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: left;
        color: #22292d;
        text-transform: uppercase;
        transition:  all 0.5s;
        &:hover{
          color: #e57632;
        }
      }
    }
    .uni-entry-meta{
      padding: 15px 0;
      span{
        font-family: "Open Sans";
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 2.14;
        letter-spacing: normal;
        text-align: left;
        color: #666666;
        padding-right: 20px;
        a{
          color: #666666;
          transition:  all 0.5s;
          &:hover{
            color: #e57632;
          }
        }
        i{
          color: #e57632;
        }
      }
    }
    .uni-entry-summary{
      margin-bottom: 30px;
      p{
        font-family: "Open Sans";
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.86;
        letter-spacing: normal;
        text-align: left;
        color: #666666;
      }
    }
    .readmore{
      a{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 140px;
        height: 44px;
        border-radius: 4px;
        background-color: #e57632;
        border: 1px solid #e57632;
        font-family: 'Proxima Nova Th';
        font-size: 14px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.86;
        letter-spacing: normal;
        text-align: center;
        text-transform: uppercase;
        color: #ffffff;
        transition:  all 0.5s;
        &:hover{
          background: transparent;
          color: #e57632;
        }
      }
    }
  }
}

/*WIDGET BLOG*/
.uni-blog-list-right{
  @media screen and (max-width: 991px) {
    margin-top: 40px;
  }
  h3{
    font-family: 'Proxima Nova Th';
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    color: #333333;
    margin-bottom: 10px;
    text-transform: uppercase;
  }
  .widget{
    margin-bottom: 60px;
  }
  //search
  .vk-newlist-banner-test-search button{
    transition: all 0.5s;
  }
  .vk-newlist-banner-test-search button:hover{
    background: #e57632;
    color: #FFF;
  }
  form{
    border-radius: 4px;
    overflow: hidden;
  }

  .vk-newlist-banner-test-search{
    width: 100%;
    height: 50px;
    border-radius: 4px;
    overflow: hidden;
    background-color: #22292d;
    border: solid 1px #e0e0e0;
    input{
      width: 70%;
      height: 50px;
      border: none;
      outline: none;
      color: #777;
      background: transparent;
      font-family: 'Open Sans', sans-serif;
      font-weight: 500;
      font-style: normal;
      font-size: 14px;
      text-indent: 15px;
    }
    button{
      width: 50px;
      height: 48px;
      border-radius: 4px;
      background-color: #22292d;
      border: none;
      outline: none;
      text-align: center;
      line-height: 44px;
      color: #aeaeae;
      font-size: 14px;
      float: right;
      transition:  all 0.5s;
      &:hover{
        color: #e57632;
        background: transparent;
      }
    }
  }

  //category
  .uni-widget-category{
    padding-top: 20px;
    ul{
      li{
        a{
          display:  block;
          font-family: "Open Sans";
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 2.57;
          letter-spacing: normal;
          text-align: left;
          color: #666666;
          transition:  all 0.5s;
          &:hover{
            color: #e57632;
            margin-left: 20px;
          }
          i{
            color: #e57632;
            padding-right: 10px;
          }
        }
      }
    }
  }

  //POPULAR POST
  .uni-widget-popular-posts{
    padding-top: 30px;
    ul{
      li{
        margin-bottom: 20px;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
    .item-img{
      width: 100px;
      height: 70px;
      overflow: hidden;
      float:  left;
      img{
        width: 100%;
        height: auto;
        transition:  all 0.5s;
        &:hover{
          transform: scale(1.1);
        }
      }
    }
    .item-caption{
      padding-left: 110px;
      h4{
        a{
          font-family: "Open Sans";
          font-size: 14px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #22292d;
          transition:  all 0.5s;
          &:hover{
            color: #e57632;
          }
        }
      }
      .time{
        font-family: "Open Sans";
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #aeaeae;
        i{
          color: #e57632;
          padding-right: 10px;
        }
      }
    }
  }

  //ARCHIVES
  .uni-widget-archive{
    padding-top: 30px;
    ul{
      li{
        margin-bottom: 10px;
        a{
          display: block;
          font-family: "Open Sans";
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 50px;
          padding-left: 20px;
          letter-spacing: normal;
          text-align: left;
          color: #666666;
          height: 50px;
          background-color: #f4f4f4;
          box-shadow: inset 5px 0 0 0 #aeaeae;
          transition:  all 0.5s;
          &:hover{
            color: #e57632;
            box-shadow: inset 5px 0 0 0 #e57632;
          }
        }
      }
    }
  }

  //TAGS CLOUND
  .uni-widget-tagsclound{
    padding-top: 30px;
    ul{
      li{
        display:  inline-block;
        margin: 0 10px 10px 0;
        a{
          display:  block;
          font-family: "Open Sans";
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 34px;
          padding: 0 20px;
          box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.2);
          letter-spacing: normal;
          text-align: center;
          color: #666666;
          transition:  all 0.5s;
          &:hover{
            color: #fff;
            background: #e57632;
          }
        }
      }
    }
  }
}