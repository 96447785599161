/*INVENTORY GRID*/
.uni-iventory-grid-body{
  padding: 80px 0;
  .uni-inventory-grid-right-content{
    .item{
      margin-bottom: 30px;
      .item-img{
        position:  relative;
        overflow: hidden;
        &:hover{
          img{
            transform: scale(1.1);
          }
        }
        img{
          width: 100%;
          height: auto;
          transition:  all 0.5s;
        }
        .prices{
          position: absolute;
          right: 0px;
          bottom: 20px;
          span{
            display:  block;
            min-width: 120px;
            font-family: 'Proxima Nova Th';
            font-size: 20px;
            font-weight: bold;
            font-style: italic;
            font-stretch: normal;
            line-height: 40px;
            letter-spacing: normal;
            text-align: center;
            color: #ffffff;
            background-color: #e57632;
          }
        }
      }
      .item-caption{
        h2{
          padding-top: 20px;
          a{
            display:  block;
            font-family: 'Proxima Nova Th';
            font-size: 18px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: 0.94;
            letter-spacing: normal;
            text-align: left;
            color: #333333;
            transition:  all 0.5s;
            text-transform:  uppercase;
            &:hover{
              color: #e57632;
            }
          }
        }
        ul{
          padding-top: 15px;
          li{
            width: 50%;
            float:  left;
            margin-bottom: 20px;            
            .left-item-info{
              padding-left: 30px;
              font-family: "Open Sans";
              font-size: 14px;
              font-weight: normal;
              font-style: normal;
              font-stretch: normal;              
              letter-spacing: normal;
              text-align: left;
              color: #666666;
            }
          }
        }
      }
    }
  }
}