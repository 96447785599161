/*-----------FOOTER-----------*/
.footer-default{
  position: relative;
  background-color: #22292d;
  .footer-main-content{
    padding: 60px 0;
    @media screen and (max-width:991px){
      padding: 30px 0 60px 0;
    }
    @media screen and (max-width:767px){
      padding: 0 0 60px 0;
    }
  }
  &.fstyle2{
    .footer-main-content{
      padding-top: 40px;
      @media screen and (max-width: 991px) {
          padding-top: 0;
      }
    }
  }
  .footer-main-content-element{
    padding-top: 40px;
    .widget-title{
      font-family: 'Proxima Nova Rg';
      font-size: 18px;
      font-weight: bold;
      font-style: italic;
      font-stretch: normal;
      line-height: 1.67;
      letter-spacing: normal;
      text-align: left;
      color: #e57632;
      margin: 0;
      padding-bottom: 20px;
      span{
        color: #fff;
      }
    }
    .widget-content{
      p{
        font-family: "Open Sans";
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.86;
        letter-spacing: normal;
        text-align: left;
        color: #aeaeae;
        margin-bottom: 0;
      }
      .uni-social{
        padding-top: 30px;
        ul{
          display:  inline-block;
          li{
            float: left;
            margin-right: 10px;
            @media screen and (max-width: 1199px) and (min-width: 992px) {
              margin-right: 5px;
            }
            a{
              display: flex;
              align-items: center;
              justify-content: center;
              width: 44px;
              height: 44px;
              background-color: #343c40;
              font-family: FontAwesome;
              font-size: 14px;
              font-weight: normal;
              font-style: normal;
              font-stretch: normal;
              line-height: 1.86;
              letter-spacing: normal;
              text-align: center;
              color: #aeaeae;
              transition: all 0.5s;
              &:hover{
                color: #fff;
                background: #e57632;
              }
            }
          }
        }
      }
      .uni-footer-business-hours{
        h4{
          font-family: 'Proxima Nova Th';
          font-size: 14px;
          color: #aeaeae;
        }
        .item{
          border-bottom: 1px solid #343c40;
          padding-bottom: 30px;
          margin-bottom: 30px;
          &:last-child{
            border-bottom: none;
            padding-bottom: 0;
            margin-bottom: 0;
          }
        }
      }
      .uni-footer-contact-info{
        ul{
          li{
            padding-bottom: 15px;
            .icon{
              float: left;
              font-family: FontAwesome;
              font-size: 14px;
              font-weight: normal;
              font-style: normal;
              font-stretch: normal;
              line-height: 2.5;
              letter-spacing: normal;
              text-align: left;
              color: #e57632;
            }
            .text{
              padding-left: 30px;
            }
          }
        }
      }
      .uni-footer-newletter{
        .input-group{
          border-radius: 4px;
          padding-bottom: 20px;
        }
        .form-control{
          height: 44px;
          background: #343c40;
          border: none;
        }
        span{
          .btn-theme{
            height: 44px;
            background: #343c40;
            color: #e57632
          }
        }
      }
    }
  }
  .copyright-area{
    background-color: #343c40;
    position: relative;
    p{
      font-family: "Open Sans";
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.86;
      letter-spacing: normal;
      text-align: left;
      color: #aeaeae;
      margin-bottom: 0;
      padding: 26px 0;
      @media screen and (max-width:991px){
        text-align:center;
      }
    }
    .copyright-menu{
      text-align: right;
      @media screen and (max-width:991px){
            text-align:center;
          }
      li{
        display: inline-block;
        a{
          padding: 25px 10px;
          display: block;
          font-family: "Open Sans";
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 2.14;
          letter-spacing: normal;

          text-align: right;
          color: #ffffff;
          transition:  all 0.5s;
          @media screen and (max-width: 991px) {
            padding: 0 10px;
          }
          @media screen and (max-width: 1199px) and (min-width: 992px) {
            padding: 25px 5px;
          }
          &:hover{
            color: #e57632;
          }

        }
      }
    }
  }
}

#toTop{
  position: absolute;
  left: 50%;
  transform: translate(-50%,0);
    text-align: center;
  top: -23px;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: #343c40;
  border: solid 3px #22292d;
  font-family: FontAwesome;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: center;
  color: #aeaeae;
  transition:  all 0.5s;
  &:hover{
    border-color: #e57632;
    .btn{
      color: #e57632;
    }
  }
  .btn{
    transition:  all 0.5s;
  }
}

/*load page*/
.load-page{
  position: absolute;
  z-index: 999999999;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  .sk-wave {
    margin: 40px auto;
    width: 50px;
    height: 40px;
    text-align: center;
    font-size: 10px; }
  .sk-wave .sk-rect {
    background-color: #e57632;
    height: 100%;
    width: 6px;
    display: inline-block;
    -webkit-animation: sk-waveStretchDelay 1.2s infinite ease-in-out;
    animation: sk-waveStretchDelay 1.2s infinite ease-in-out; }
  .sk-wave .sk-rect1 {
    -webkit-animation-delay: -1.2s;
    animation-delay: -1.2s; }
  .sk-wave .sk-rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s; }
  .sk-wave .sk-rect3 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s; }
  .sk-wave .sk-rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s; }
  .sk-wave .sk-rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s; }

  @-webkit-keyframes sk-waveStretchDelay {
    0%, 40%, 100% {
      -webkit-transform: scaleY(0.4);
      transform: scaleY(0.4); }
    20% {
      -webkit-transform: scaleY(1);
      transform: scaleY(1); } }

  @keyframes sk-waveStretchDelay {
    0%, 40%, 100% {
      -webkit-transform: scaleY(0.4);
      transform: scaleY(0.4); }
    20% {
      -webkit-transform: scaleY(1);
      transform: scaleY(1); } }
}