/*PROCESS*/
.uni-shortcode-progress{
  h2{
    font-family: 'Proxima Nova Th';
    font-size: 30px;
    font-weight: bold;
    line-height: 1.25;
    text-align: center;
    color: #404040;
    padding-bottom: 60px;
  }

  .uni-shortcode-progress-body{
    padding: 100px 0;
  }
}

//THICK BAR
.uni-processbar-thick{
  margin-top: 100px;
  .progress {
    height: 40px;
  }
  .progress .skill {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    line-height: 35px;
    padding: 0;
    margin: 0 0 0 20px;
    text-transform: uppercase;
  }
  .progress .skill .val {
    float: right;
    font-style: normal;
    margin: 0 20px 0 0;
  }

  .progress-bar {
    text-align: left;
    transition-duration: 3s;
    background: #e57632;
  }
}

//CIRCLE BAR
.uni-processbar-circle {
  margin-top: 100px;
  .uni-numbertext{
    position: relative;
    z-index: 2;
    font-family:'Montserrat', sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.25;
    text-align: center;
    color: #e57632;
    margin: 0 auto;
  }
  .process-circle-item{
    @media screen and (max-width: 991px){
      margin-bottom: 30px;
    }
  }
  .tox-progress-content{
    &:after{
      position: absolute;
      display: block;
      content: " ";
      border-radius: 50%;
      background-color: #fff;
      width: 92%;
      height: 92%;
      -webkit-transition-property: all;
      -moz-transition-property: all;
      -o-transition-property: all;
      transition-property: all;
      -webkit-transition-duration: 0.2s;
      -moz-transition-duration: 0.2s;
      -o-transition-duration: 0.2s;
      transition-duration: 0.2s;
      -webkit-transition-timing-function: ease-in;
      -moz-transition-timing-function: ease-in;
      -o-transition-timing-function: ease-in;
      transition-timing-function: ease-in;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    }
  }
}

// MEDIUM BAR
.uni-processbar-medium{
  margin-top: 100px;
  .tooltip{
    position:relative;
    float:right;
    .tooltip-inner{
      background-color: transparent;
      color:#e57632;
      font-weight:bold;
      font-size:13px;
      border-radius: 0;
    }
  }
  .tooltip.top .tooltip-arrow{
    border: none;
  }
  section{
    margin:140px auto;
    height:1000px;
  }
  .progress{
    height: 9px;
    border-radius:0;
    overflow:visible;
    background: rgba(255, 255, 255, 0.3);
    margin-bottom: 60px;
  }
  .progress-bar{
    position: relative;
    background:#e57632;
    -webkit-transition: width 1.5s ease-in-out;
    transition: width 1.5s ease-in-out;
    &:after{
      content: "";
      display: block;
      position: absolute;
      top: -4px;
      right: 0;
      width: 2px;
      height: 16px;
      background-color: #e57632;
    }
  }
}

//SLIM BAR
.uni-processbar-slim{
  .tooltip{
    position:relative;
    float:right;
    .tooltip-inner{
      background-color: transparent;
      color:#e57632;
      font-weight:bold;
      font-size:13px;
      border-radius: 0;
    }
  }
  .tooltip.top .tooltip-arrow{
    border: none;
  }
  section{
    margin:140px auto;
    height:1000px;
  }
  .progress{
    height: 2px;
    border-radius:0;
    overflow:visible;
    background: rgba(255, 255, 255, 0.3);
    margin-bottom: 60px;
  }
  .progress-bar{
    position: relative;
    background:#e57632;
    -webkit-transition: width 1.5s ease-in-out;
    transition: width 1.5s ease-in-out;
    &:after{
      content: "";
      display: block;
      border: 5px solid #e57632;
      border-radius: 50%;
      position: absolute;
      top: -4px;
      right: 0;
    }
  }
}