.uni-shortcode-counter{
  h2{
    font-family: 'Proxima Nova Th';
    font-size: 30px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    text-transform:  uppercase;
    color: #22292d;
    padding-bottom: 60px;
  }
}

//COUNTER BORDER
.uni-border-counter{
  padding: 80px 0;
  .uni-counter-item{
    border: 1px solid #404040;
    border-radius: 3px;
    padding: 50px 0;
    text-align: center;
    transition: all 0.5s;
    @media screen and (max-width: 991px) {
      margin-bottom: 20px;
    }
    &:hover{
      background: #e57632;
      border: 1px solid #e57632;
      i{
        color: #fff;
      }
      .uni-line{
        background: #fff;
      }
      .counter{
        color: #fff;
      }
      h4{
        color: #fff;
      }
    }
    .uni-line{
      width: 40px;
      height: 4px;
      border-radius: 2px;
      background-color: #e57632;
      margin:  0 auto;
      transition: all 0.5s;
    }
    .counter{
      display: block;
      font-family: 'Proxima Nova Th';
      font-size: 36px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 0.83;
      letter-spacing: normal;
      text-align: center;
      color: #22292d;
      padding-bottom: 20px;
    }
    i{
      font-family: FontAwesome;
      font-size: 60px;
      text-align: center;
      color: #404040;
      transition: all 0.5s;
    }
    h4{
      font-family: 'Proxima Nova Th';
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
      color: #404040;
      transition: all 0.5s;
    }
  }
}

/*COUNTER BACKGROUND*/
.uni-counter-background{
  padding: 80px 0;
  h2{
    color: #fff;
  }
  .uni-counter-item{
    ul{
      li{
        &.icons{
          font-family: FontAwesome;
          font-size: 48px;
          text-align: center;
          color: #e57632;
        }
        &.number-about{
          display: block;
          text-align: center;
          padding-top: 40px;
          padding-bottom: 20px;
          span{
            display: inline-block;
            font-family: 'Proxima Nova Th';
            font-size: 48px;
            font-weight: bold;
            color: #ffffff;
          }
        }
        &.uni-name{
          display: block;
          font-family: 'Proxima Nova Th';
          font-size: 14px;
          font-weight: bold;
          line-height: 1.71;
          text-align: center;
          text-transform: uppercase;
          color: rgba(255, 255, 255, 0.3);
        }
      }
    }
  }
}

/*COUNTER WITHOUT BACKGROUND*/
.uni-counter-without-background{
  padding: 80px 0;
  .uni-counter-item{
    ul{
      li{
        &.icons{
          font-family: FontAwesome;
          font-size: 48px;
          text-align: center;
          color: #e57632;
        }
        &.number-about{
          display: block;
          text-align: center;
          padding-top: 40px;
          padding-bottom: 20px;
          span{
            display: inline-block;
            font-family: 'Proxima Nova Th';
            font-size: 48px;
            font-weight: bold;
            color: #22292d;
          }
        }
        &.uni-name{
          display: block;
          font-family: 'Proxima Nova Th';
          font-size: 14px;
          font-weight: bold;
          line-height: 1.71;
          text-align: center;
          text-transform: uppercase;
          color: #aeaeae;
        }
      }
    }
  }
}