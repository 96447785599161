/*SINGLE PRODUCT*/
.uni-single-product{
  #content{
    padding: 100px 0;
  }
  .uni-single-product-body{
    background: #f4f4f4;
    .row{
      display: -ms-flexbox;
      display: -webkit-box;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
    .sidebar-shop{
      @media screen and  (max-width: 991px){
        order: 2;
        margin-top: 60px;
        width: 100%;
      }
    }
  }
}

.uni-single-product-right{
  .right{
    @media screen and  (max-width: 991px){
      margin-top: 30px;
    }
    .entry-title{
      font-family: 'Proxima Nova Th';
      font-size: 30px;
      font-weight: bold;
      font-style: italic;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      text-transform: uppercase;
      color: #22292d;
    }
    .uni-box-vote{
      padding-top: 10px;
      padding-bottom: 15px;
      span{
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        text-align: left;
        color: #22292d;
        i{
          font-family: FontAwesome;
          font-size: 12px;
          letter-spacing: 1.2px;
          color: #e57632;
        }
      }
    }
    .price{
      padding-bottom: 10px;
      .woocommerce-Price-amount{
        font-size: 20px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: left;
        color: #22292d;
      }
    }
    .description{
      p{
        font-family: "Open Sans";
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: left;
        color: #666666;
      }
    }
    .quantity{
      float: left;
      label{
        font-family: 'Open Sans', sans-serif;
        font-size: 11px;
        font-weight: bold;
        line-height: 2.18;
        text-align: left;
        color: #666666;
        text-transform: uppercase;
      }
      input{
        width: 75px;
        height: 36px;
        border-radius: 3px;
        border: solid 1px #aeaeae;
      }
      .form-control{
        margin-bottom: 0;
      }
      .form-group{
        margin-bottom: 0;
      }
    }
    .cart{
      position: relative;
      padding-top: 20px;
    }
    .single_add_to_cart_button{
      position: absolute;
      bottom: 0;
      background: #e57632;
      padding: 6px 24px;
      font-family: 'Proxima Nova Th';
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.71;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      text-transform: uppercase;
      border: 1px solid #e57632;
      border-radius: 3px;
      margin-left: 10px;
      transition:  all 0.5s;
      &:hover{
        color: #e57632;
        background: transparent;
      }
    }
    .product_meta{
      padding-top: 25px;
      span{
        font-family: 'Proxima Nova Th';
        font-size: 14px;
        font-weight: bold;
        color: #404040;
        text-transform: uppercase;
        a{
          font-family: 'Open Sans', sans-serif;
          font-size: 14px;
          font-weight: normal;
          text-align: left;
          color: #808080;
          text-transform: none;
          &:after{
            content: ",";
          }
          &:last-child{
            &:after{
              content: "";
            }
          }
          &:hover{
            color: #e57632;
          }
        }
      }
    }
  }
  .product-slide{
    ul{
      li{
        display: inline-block;
      }
    }
    .img-slide{
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      width: 100%;
      height: 370px;
      overflow: hidden;
      margin-bottom: 10px;
      padding: 30px;
      @media screen and (max-width:767px) and (min-width:575px){
          margin-bottom: 20px;
      }

      .filter2 {
        display: none;
        &.active{
          display: block;
        }
      }
      img{
        width: 100%;
        height: auto;
      }
    }
    .row-fix{
      .img-small{
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        height: 92px;
        padding: 10px;
        margin-right: 10px;
        @media screen and (max-width:767px) and (min-width:575px){
          background: transparent;    
          display: inline-block;
          height: 100%;
        }
        &.active{
          border: solid 1px #e57632;
          @media screen and (max-width:767px) and (min-width:575px){
            border: none;
          }
        }
        img{
          width: 100%;
          height: auto;
        }
      }
    }
  }
  .comment-tabs{
    padding-top: 60px;
  }
  .uni-shortcode-tabs-default .tabbable-line > .tab-content{
    background: transparent;
    padding-top: 40px;
    h3{
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 0.94;
      letter-spacing: normal;
      text-align: left;
      text-transform: uppercase;
      color: #22292d;
    }
  }
  .uni-shortcode-tab-1 .tabbable-line > .nav-tabs > li.active > a span{
    text-transform: uppercase;
  }
  .panel{
    background: transparent;
    box-shadow: none;
  }
  #description{
    p{
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      line-height: 1.71;
      text-align: left;
      color: #808080;
    }
  }
}
.related{
  padding-top: 30px;
  h2{
    font-family: 'Proxima Nova Th';
    font-size: 30px;
    font-weight: bold;
    font-style: italic;
    line-height: 1;
    text-align: left;
    color: #404040;
    text-transform: uppercase;
    @media screen and (max-width:767px){
      font-size: 26px;
    }
    span{
      color: #e57632;
    }
  }
}

#review{
  .comment-item{
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 20px;
    margin-bottom: 20px;
    &:last-child{
      border-bottom: none;
      padding: 0;
      margin: 0;
    }
    .comment_container{
      .comment-left{
        background-color: #fff;
        width: 100px;
        height: 130px;
        float: left;
        .star-rating{
          text-align: center;
          span{
            font-family: FontAwesome;
            font-size: 14px;
            line-height: 2.14;
            letter-spacing: 0.7px;
            color: #e57632;
          }
        }
      }
      .comment-text{
        padding-left: 120px;
        .meta{
          h3{
            font-size: 14px;
            font-weight: bold;
            text-align: left;
            color: #363636;
          }
          span{
            font-family: 'Open Sans', sans-serif;
            font-size: 15px;
            font-weight: 600;
            text-align: left;
            color: #888888;
          }
        }
        .description{
          padding-top: 10px;
          p{
            font-family: 'Open Sans', sans-serif;
            font-size: 15px;
            font-weight: 300;
            text-align: left;
            color: #888888;
          }
        }
      }
    }
  }
}

.review-loadmore-button{
  font-family: 'Proxima Nova Th';
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  color: #ffffff;
  width: 100%;
  height: 44px;
  background: #e57632;
  border: 1px solid #e57632;
  border-radius: 3px;
  transition: all 0.5s;
  &:hover{
    background: #fff;
    color: #e57632;
  }
}

//ADD YOUR REVIREW
.uni-single-product-add-your-review{
  padding-top: 60px;
  .form-group{
    width: 100%;
  }
  h3{
    font-family: 'Proxima Nova Th';
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    color: #404040;
    padding-bottom: 30px;
  }
  span{
    i{
      color: #e57632;
    }
  }
  input[type=checkbox]{

  }
  input[type=text]{
    height: 44px;
    border-radius: 3px;
    border: solid 1px #d7d7d7;
    margin:  20px 0;
  }
  textarea{
    height: 200px;
    border-radius: 3px;
    border: solid 1px #d7d7d7;
    margin-bottom: 20px;
  }
  .btn{
    background: #e57632;
    border: 1px solid #e57632;
    border-radius: 3px;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: bold;
    line-height: 2.31;
    text-align: center;
    color: #fff;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    width: 19%;
    @media screen and (max-width: 991px) {
      width: 100%;
    }
    &:hover{
      background: #fff;
      color: #e57632;
    }
  }
}

//sidebar SHOP
.uni-shop-siderbar-left{
  .vk-newlist-banner-test-search button{
    transition: all 0.5s;
  }
  .vk-newlist-banner-test-search button:hover{
    background: #e57632;
    color: #FFF;
  }
  form{
    background: #f0f0f0;
  }

  .vk-newlist-banner-test-search{
    width: 100%;
    height: 50px;
    background: #fff;
    border: solid 1px #e0e0e0;
    border-radius: 4px;
    input{
      width: 80%;
      height: 50px;
      border: none;
      outline: none;
      color: #777;
      background: transparent;
      font-family: 'Open Sans', sans-serif;
      font-weight: 500;
      font-style: normal;
      font-size: 14px;
      text-indent: 15px;
    }
    button{
      width: 40px;
      height: 48px;
      background: #fff;
      border: none;
      outline: none;
      text-align: center;
      line-height: 44px;
      color: #aeaeae;
      font-size: 14px;
      float: right;
      border-radius: 4px;
      transition:  all 0.5s;
      &:hover{
        color: #e57632;
        background: transparent;
      }
    }
  }


  .vk-shop-your-card{
    background: #f0f0f0;
    padding: 20px;
    padding-bottom: 40px;
    margin-top: 30px;
    p{
      font-family: "Raleway", sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 2.14;
      text-align: left;
      color: #7b7b7b;
      margin-bottom: 0px;
    }
    h3{
      font-family: 'Proxima Nova Th';
      font-size: 18px;
      font-weight: bold;
      line-height: 1.67;
      color: #333333;
      margin: 0px;
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid #e0e0e0;
      text-transform: uppercase;
    }
    .vk-shop-your-card-item{
      border-bottom: 1px solid #e0e0e0;
      padding-bottom: 10px;
      margin-bottom: 10px;
      .vk-shop-item-img{
        float: left;
        img{
          width: 60px;
          height: 60px;
          border: solid 1px #f0f0f0;
          overflow: hidden;
        }
      }
      .vk-shop-item-info{
        padding-left: 80px;
        h4{
          font-family: 'Proxima Nova Th';
          font-size: 14px;
          text-align: left;
          color: #404040;
          margin: 0px;
        }
        p{
          font-family: 'Open Sans', sans-serif;
          font-size: 14px;
          line-height: 1.57;
          text-align: left;
          color: #808080;
          margin-bottom: 0px;
        }
        strong{
          font-family: "Raleway", sans-serif;
          font-size: 14px;
          line-height: 1.57;
          float: right;
          color: #333333;
          font-weight: bold;
        }
      }
    }
    .vk-total{
      padding-top: 10px;
      p{
        font-family: "Raleway", sans-serif;
        font-size: 14px;
        font-weight: bold;
        line-height: 1.57;
        text-align: left;
        color: #333333;
        span{
          float: right;
        }
      }
    }
    .vk-your-card-btn{
      margin-top: 30px;
      ul{
        margin: 0px;
        padding: 0px;
        li{
          display: inline-block;
          margin-left: 10px;
          transition: all 0.5s;
          &:first-child{
            margin-left: 0;
          }
          &:hover{
            a{
              &.view-card{
                border: 1px solid #e57632;
                background: transparent;
                color: #e57632;
                text-transform: inherit;
              }
              &.check-out{
                background: transparent;
                color: #e57632;
                text-transform: inherit;
              }
            }
          }
          a{
            border: 1px solid #404040;
            border-radius: 3px;
            font-family: "Raleway", sans-serif;
            font-size: 14px;
            font-weight: bold;
            text-align: center;
            padding: 11px 15px;
            &.view-card{
              background: transparent;
              color: #404040;
              transition:  all 0.5s;
              text-transform: inherit;
            }
            &.check-out{
              background: #e57632;
              border: 1px solid #e57632;
              color: #fff;
              transition:  all 0.5s;
              text-transform: inherit;
            }
          }
        }
      }
    }
  }

  .vk-shop-category{
    //background: #fff;
    margin-top: 60px;
    h3{
      font-family: 'Proxima Nova Th';
      font-size: 18px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.67;
      letter-spacing: normal;
      text-align: left;
      color: #22292d;
      text-transform: uppercase;
    }
    ul{
      margin: 0;
      padding: 0;
      padding-top: 10px;
      li{
        list-style-type: none;
        a{
          display: block;
          font-family: "Open Sans";
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 2.57;
          letter-spacing: normal;
          text-align: left;
          color: #666666;
          transition:  all 0.5s;
          &:hover{
            color: #e57632;
            margin-left: 20px;
          }
          i{
            margin-right: 5px;
          }
        }
      }
    }
  }
  .uni-filter-price{
    //background: #ffffff;
    margin-top: 45px;
    h3{
      font-family: 'Proxima Nova Th';
      font-size: 18px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.67;
      letter-spacing: normal;
      text-align: left;
      color: #22292d;
      text-transform: uppercase;
    }
  }

  .uni-best-seller{
    //background: #ffffff;
    margin-top: 60px;
    .vk-newlist-details{
      padding-top: 30px;
    }
    h3{
      font-family: 'Proxima Nova Th';
      font-size: 18px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.67;
      letter-spacing: normal;
      text-align: left;
      color: #22292d;
      text-transform: uppercase;
    }
    h4{
      margin-bottom: 0;
      a{
        font-family: "Open Sans";
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.86;
        letter-spacing: normal;
        text-align: left;
        color: #22292d;
        transition: all 0.5s;
        &:hover{
          color: #e57632;
        }
      }
    }
  }

  #slider-range{
    width: 100%;
    height: 5px;
    background: #E0E0E0;
    border-radius: 2px;
    border: none;
    margin-top: 40px;
  }
  .ui-state-active{
    border-color: transparent;
    background: transparent;
  }
  .ui-state-default{
    position: relative;
    border-color: transparent;
    background: transparent;
    outline: none;
    &:before{
      content: '';
      width: 16px !important;
      height: 16px !important;
      background: #e57632 !important;
      border: none;
      border-radius: 50%;
      outline: none;
      top: -2px;
      position: absolute;
    }
    &:after{
      content: '';
      width: 10px !important;
      height: 10px !important;
      background: #fff !important;
      border: none;
      border-radius: 50%;
      outline: none;
      top: 1px;
      left: 3px;
      position: absolute;
    }
  }
  .ui-slider-horizontal .ui-slider-range{
    background: #e57632;
    margin-left: -5px;
  }
  .label-filter-price{
    font-size: 14px;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: center;
    color: #666666;
    margin-top: 25px;
    input{
      font-size: 14px;
      line-height: 1.21;
      letter-spacing: normal;
      text-align: center;
      color: #666666;
      border: none;
      background: transparent;
    }
  }

  .btn-filter-prince{
    width: 100%;
    height: 44px;
    border: none;
    outline: none;
    background: #e57632;
    font-family: 'Proxima Nova Th';
    font-weight: bold;
    font-style: normal;
    font-size: 14px;
    border-radius: 3px;
    color: #FFF;
    position: relative;
    margin-top: 25px;
    transition:all 0.5s;
    &:hover{
      border:1px solid #e57632;
      background: none;
      color: #e57632;
      transition:all 0.5s;
    }
  }

  .vk-book-details{
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e0e0e0;
    &:last-child{
      border-bottom: none;
    }
    .vk-best-seller-img{
      float: left;
      border: 1px solid #f0f0f0;
      img{
        width: 60px;
        height: 60px;
        overflow: hidden;
      }
      &:hover{
        border-color: #e57632;
      }
    }
    .vk-best-seller-info{
      padding-left: 80px;
      ul{
        margin: 0;
        padding: 0;
        li{
          display: inline-block;
          i{
            color: #e57632;
          }
        }
      }
      p{
        font-weight: bold;
        color: #aeaeae;
        margin: 0;
      }
    }
  }
}