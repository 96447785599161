/*PARTNER*/
.uni-shortcode-partner-body{
  padding: 80px 0;
}
.uni-partner-default{
  .partner-item{
    display:  flex;
    align-items: center;
    justify-content: center;
    min-height: 110px;
  }
}
.uni-partner-background{
  position: relative;
  display: flex;
  padding-top: 40px;
  .partner-item{
    border-radius: 4px;
    background-color: #e0e0e0;
  }
}