.col-unisp-1, .col-unisp-2, .col-unisp-3, .col-unisp-4,  .col-unisp-5, .col-unisp-6, .col-unisp-7,  .col-unisp-8,  .col-unisp-9, .col-unisp-10, .col-unisp-11,  .col-unisp-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
@media (max-width: 575px) {
  .col-unisp-1, .col-unisp-2, .col-unisp-3, .col-unisp-4, .col-unisp-5, .col-unisp-6, .col-unisp-7, .col-unisp-8, .col-unisp-9, .col-unisp-10, .col-unisp-11, .col-unisp-12 {
    float: left;
  }
  .col-unisp-12 {
    width: 100%;
  }
  .col-unisp-11 {
    width: 91.66666667%;
  }
  .col-unisp-10 {
    width: 83.33333333%;
  }
  .col-unisp-9 {
    width: 75%;
  }
  .col-unisp-8 {
    width: 66.66666667%;
  }
  .col-unisp-7 {
    width: 58.33333333%;
  }
  .col-unisp-6 {
    width: 50%;
  }
  .col-unisp-5 {
    width: 41.66666667%;
  }
  .col-unisp-4 {
    width: 33.33333333%;
  }
  .col-unisp-3 {
    width: 25%;
  }
  .col-unisp-2 {
    width: 16.66666667%;
  }
  .col-unisp-1 {
    width: 8.33333333%;
  }
  .col-unisp-pull-12 {
    right: 100%;
  }
  .col-unisp-pull-11 {
    right: 91.66666667%;
  }
  .col-unisp-pull-10 {
    right: 83.33333333%;
  }
  .col-unisp-pull-9 {
    right: 75%;
  }
  .col-unisp-pull-8 {
    right: 66.66666667%;
  }
  .col-unisp-pull-7 {
    right: 58.33333333%;
  }
  .col-unisp-pull-6 {
    right: 50%;
  }
  .col-unisp-pull-5 {
    right: 41.66666667%;
  }
  .col-unisp-pull-4 {
    right: 33.33333333%;
  }
  .col-unisp-pull-3 {
    right: 25%;
  }
  .col-unisp-pull-2 {
    right: 16.66666667%;
  }
  .col-unisp-pull-1 {
    right: 8.33333333%;
  }
  .col-unisp-pull-0 {
    right: auto;
  }
  .col-unisp-push-12 {
    left: 100%;
  }
  .col-unisp-push-11 {
    left: 91.66666667%;
  }
  .col-unisp-push-10 {
    left: 83.33333333%;
  }
  .col-unisp-push-9 {
    left: 75%;
  }
  .col-unisp-push-8 {
    left: 66.66666667%;
  }
  .col-unisp-push-7 {
    left: 58.33333333%;
  }
  .col-unisp-push-6 {
    left: 50%;
  }
  .col-unisp-push-5 {
    left: 41.66666667%;
  }
  .col-unisp-push-4 {
    left: 33.33333333%;
  }
  .col-unisp-push-3 {
    left: 25%;
  }
  .col-unisp-push-2 {
    left: 16.66666667%;
  }
  .col-unisp-push-1 {
    left: 8.33333333%;
  }
  .col-unisp-push-0 {
    left: auto;
  }
  .col-unisp-offset-12 {
    margin-left: 100%;
  }
  .col-unisp-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-unisp-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-unisp-offset-9 {
    margin-left: 75%;
  }
  .col-unisp-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-unisp-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-unisp-offset-6 {
    margin-left: 50%;
  }
  .col-unisp-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-unisp-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-unisp-offset-3 {
    margin-left: 25%;
  }
  .col-unisp-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-unisp-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-unisp-offset-0 {
    margin-left: 0%;
  }
}
//HEADER TOPBAR*/
.uni-header-topbar{
  background: #181e22;
  .uni-topbar-list-left{
    float: left;
    padding: 5px 0;
    @media screen and (max-width: 991px) {
      display:  none;
    }
    &.float-l{
      float: left;
    }
    ul{
      margin: 0;
      padding: 0;
      li{
        list-style-type: none;
        display: inline-block;
        padding-right: 30px;
        @media screen and (max-width: 1199px) and (min-width: 992px) {
          padding-right: 10px;
        }
        &:last-child{
          padding-right: 0;
        }
        .icon-holder{
          float: left;
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
          @media screen and (max-width: 1199px) and (min-width: 992px) {
            margin-right: 0;
          }
          i{
            font-family: FontAwesome;
            font-size: 36px;
            text-align: center;
            color: #e57632;
            @media screen and (max-width: 1199px) and (min-width: 992px) {
              font-size: 24px;
            }
          }
        }
        .text-holder{
          padding-left: 51px;
          span{
            font-family: 'Proxima Nova';
            font-size: 12px;
            text-align: left;
            color: #e57632;
            font-weight: bold;
            text-transform: uppercase;
          }
          h4{
            font-family: 'Proxima Nova';
            font-size: 14px;
            text-align: left;
            color: #ebeff0;
            font-weight: bold;
            margin: 0;
            text-transform: uppercase;
            @media screen and (max-width: 1199px) and (min-width: 992px) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  .uni-topbar-list-right{
    float: right;
    @media screen and (max-width: 991px)  {
      float: none;
    }
    .uni-select-con-mon{
      float: left;
      ul{
        li{
          display:  inline-block;
          .selection{
            background-color:transparent;
            padding: 16px;
            @media screen and (max-width: 1199px) and (min-width: 992px) {
              background-color:transparent;
              padding: 16px 7px;
            }
            i{
              float: right;
              padding-left: 5px;
            }
            .text{
              font-family: 'Proxima Nova';
              font-size: 14px;
              font-weight: normal;
              font-style: normal;
              font-stretch: normal;
              line-height: 1.14;
              letter-spacing: normal;
              text-align: right;
              color: #ebeff0;
              @media screen and (max-width: 1199px) and (min-width: 992px) {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
    .uni-search-top-head{
      float: right;
      .icons-search{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        background-color: #343c40;
        font-family: FontAwesome;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.14;
        letter-spacing: normal;
        text-align: center;
        color: #ebeff0;
        &:hover{
          cursor: pointer;
        }
        .search{
          width: 300px;
          height: 50px;
          overflow: hidden;
          position: absolute;
          margin-left: -125px;
          visibility: hidden;
          transition: all 0.5s;
          .search-field {
            width: 100%;
            height: 50px;
            margin-right: -300px !important;
            padding-left: 20px;
            border: none;
            outline: none;
            font-size: 14px;
            color: #999999;
            background: #343c40;
            text-transform: capitalize;
            float: right;
            transform-origin: right;
            transition: all 0.5s;
          }
          .close-search {
            width: 50px;
            height: 50px;
            right: 0;
            margin-top: 0;
            border: none;
            outline: none;
            position: absolute;
            z-index: 1000;
            background: #343c40;
            color: #e57632;
            display: none;
            transition: all 0.5s;
          }
          .block-search {
            margin-right: 0px !important;
            opacity: 1 !important;
            visibility: visible !important;
            transition: all 0.5s;
          }
        }
      }
    }
  }
}

//=======================show-hover-shortcodes============
.show-hover-shortcodes{
  position: absolute;
  width: 100%;
  top: 80px;
  z-index: 999;
  background-color: #ffffff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  border-top: 4px solid #e57632;
  display: none;
  h4{
    font-family: "Proxima Nova Th";
    font-size: 14px;
    font-weight: bold;
    line-height: 50px;
    text-align: left;
    color: #e57632;
    margin: 0;
    padding: 0 20px;
  }
  .short-code-title{
    background: #22292d;
  }
  .short-code-content{
    padding: 0 20px;
  }
  ul{
    li{
      border-bottom: 1px solid #e0e0e0;
      line-height: 50px;
      padding: 0 10px;
      &:last-child{
        border-bottom: none;
      }
      a{
        font-family: "Open Sans";
        font-size: 14px;
        line-height: 50px;
        text-align: left;
        color: #7b7b7b;
        display: block;
        i{
          margin-right: 10px;
        }
      }
      &:hover{
        a{
          color: #e57632;
        }
      }
    }
  }
}

//MAIN MENU
.is-sticky{
  visibility: hidden;
  @media screen and (min-width: 992px) {
    visibility: visible;
    position: relative;
    z-index: 9999;
  }
}
.uni-main-menu{
  background-color: #ebeff0;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  .main-menu{
    position:  relative;
  }
  .wrapper-logo{
    float: left;
    padding: 20px 0;
  }
  .uni-menu-text{
    float: right;
  }
  .cssmenu{
    float: left;
  }
  .cssmenu,
  .cssmenu ul,
  .cssmenu ul li,
  .cssmenu ul li a,
  .cssmenu #menu-button {
    margin: 0;
    padding: 0;
    border: 0;
    list-style: none;
    line-height: 1;
    display: block;
    position: relative;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .cssmenu:after,
  .cssmenu > ul:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
  }
  .cssmenu #menu-button {
    display: none;
  }
  .cssmenu {
    width: auto;
    font-family: 'Open Sans', sans-serif;
    line-height: 1;
    @media screen and (max-width: 991px) {
      display:  none;
    }
  }
  .cssmenu > ul > li {
    float: left;
  }
  .cssmenu.align-center > ul {
    font-size: 0;
    text-align: center;
  }
  .cssmenu.align-center > ul > li {
    display: inline-block;
    float: none;
  }
  .cssmenu.align-center ul ul {
    text-align: left;
  }
  .cssmenu.align-right > ul > li {
    float: right;
  }
  .cssmenu.align-right ul ul {
    text-align: right;
  }
  .cssmenu > ul > li > a {
    font-family: 'Proxima Nova Th';
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.14;
    letter-spacing: normal;
    text-align: right;
    color: #22292d;
    padding: 26px 20px;
    text-decoration: none;
    text-transform: uppercase;
    transition: color .2s ease;
    @media screen and (min-width: 992px) and (max-width:  1199px) {
      font-size: 11px;
      font-weight:  normal;
      padding: 29px 20px;
    }
  }
  .cssmenu > ul > li:hover > a,
  .cssmenu > ul > li.active > a {
    color: #e57632;
  }
  .cssmenu > ul > li.has-sub > a {
    padding-right: 25px;
    &:hover{
      &:before{
        content: '';
        display:  block;
      }
    }
  }
  .cssmenu > ul > li.has-sub > a::after {
    position: absolute;
    top: 36px;
    right: 10px;
    width: 4px;
    height: 4px;
    border-bottom: 1px solid #22292d;
    border-right: 1px solid #22292d;
    content: "";
    transform: rotate(45deg);
    transition: border-color 0.2s ease;
  }
  .cssmenu > ul > li.has-sub:hover > a::after {
    border-color: #e57632;
  }
  .cssmenu ul ul {
    position: absolute;
    left: -9999px;
    z-index: 2;
    background: #ffffff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5), inset 0px 4px 0 0 #e57632;
  }
  .cssmenu li:hover > ul {
    left: auto;
  }
  .cssmenu.align-right li:hover > ul {
    right: 0;
  }
  .cssmenu ul ul ul {
    margin-left: 100%;
    top: 0;
    background: #ffffff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5), inset 0px 4px 0 0 #e57632;
  }
  .cssmenu ul ul ul li a{
    color: #666666;
    border-top: 1px solid #e0e0e0;
  }
  .cssmenu.align-right ul ul ul {
    margin-left: 0;
    margin-right: 100%;
  }
  .cssmenu ul ul li {
    height: 0;
    transition: height .2s ease;
  }
  .cssmenu ul li:hover > ul > li {
    height: 48px;
  }
  .cssmenu ul ul li a {
    display: block;
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
    color: #666666;
    padding: 12px 20px;
    min-width: 240px;
    background: transparent;
    border-top: 1px solid #e0e0e0;
    text-decoration: none;
    transition: color .2s ease;
  }
  .cssmenu ul ul li:first-child{
    a{
      border-top: none;
    }
  }
  .cssmenu ul ul li:hover > a,
  .cssmenu ul ul li a:hover {
    color: #fff;
    background: #e57632;
  }
  .cssmenu ul ul li.has-sub > a::after {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 4px;
    height: 4px;
    border-bottom: 1px solid #222;
    border-right: 1px solid #222;
    content: "";
    transform: rotate(-45deg);
    transition: border-color 0.2s ease;
  }
  .cssmenu.align-right ul ul li.has-sub > a::after {
    right: auto;
    left: 10px;
    border-bottom: 0;
    border-right: 0;
    border-top: 1px solid #dddddd;
    border-left: 1px solid #dddddd;
  }
  .cssmenu ul ul li.has-sub:hover > a::after {
    border-color: #fff;
  }
}

.mobile-menu-container{
  .navbar-toggle {
        margin-right: 0;
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
    position: absolute;
    top: 20px;
    float: none;
    right: 0;
    z-index: 99999;
    width: 40px;
    height: 40px;
    text-align: right;
    line-height: 40px;
  }
  #cssmenu ul li a i {
    margin-right: 10px;
  }
}

@import url(https://fonts.googleapis.com/css?family=Open+Sans);
#cssmenu,
#cssmenu ul,
#cssmenu ul li,
#cssmenu ul li a,
#cssmenu #menu-button {
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none;
  line-height: 1;
  display: block;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#cssmenu:after,
#cssmenu > ul:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
#cssmenu #menu-button {
  display: none;
}
#cssmenu {
  width: auto;
  font-family: 'Open Sans', sans-serif;
  line-height: 1;
}
#cssmenu > ul > li {
  float: left;
}
#cssmenu.align-center > ul {
  font-size: 0;
  text-align: center;
}
#cssmenu.align-center > ul > li {
  display: inline-block;
  float: none;
}
#cssmenu.align-center ul ul {
  text-align: left;
}
#cssmenu.align-right > ul > li {
  float: right;
}
#cssmenu.align-right ul ul {
  text-align: right;
}
#cssmenu > ul > li > a {
  font-family: "Open Sans";
  font-size: 14px;
  line-height: 1.43;
  text-align: left;
  color: #ffffff;
  padding: 20px;
  text-decoration: none;
  text-transform: uppercase;
  transition: color .2s ease;
  @media screen and (min-width: 992px) and (max-width:  1199px) {
    font-size: 11px;
    font-weight:  normal;
  }
}
#cssmenu > ul > li:hover > a,
#cssmenu > ul > li.active > a {
  color: #000;
}
#cssmenu > ul > li.has-sub > a {
  padding-right: 25px;
  &:hover{
    &:before{
      content: '';
      display:  block;
    }
  }
}
#cssmenu > ul > li.has-sub > a::after {
  position: absolute;
  top: 27px;
  right: 10px;
  width: 4px;
  height: 4px;
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  content: "";
  transform: rotate(45deg);
  transition: border-color 0.2s ease;
}
#cssmenu > ul > li.has-sub:hover > a::after {
  border-color: #000;
}
#cssmenu ul ul {
  position: absolute;
  left: -9999px;
  z-index: 2;
  background: #f4f4f4;
}
#cssmenu li:hover > ul {
  left: auto;
}
#cssmenu.align-right li:hover > ul {
  right: 0;
}
#cssmenu ul ul ul {
  margin-left: 100%;
  top: 0;
  background: transparent;
  border-top: none;
}
#cssmenu ul ul ul li a{
  color: #fff;
  border-top: 1px solid #333;
}
#cssmenu.align-right ul ul ul {
  margin-left: 0;
  margin-right: 100%;
}
#cssmenu ul ul li {
  height: 0;
  transition: height .2s ease;
}
#cssmenu ul li:hover > ul > li {
  height: 48px;
}
#cssmenu ul ul li a {
  display: block;
  font-family: "Open Sans";
  font-size: 14px;
  text-align: left;
  color: #222222;
  padding: 17px 20px;
  min-width: 240px;
  background: transparent;
  border-top: 1px solid #e0e0e0;
  text-decoration: none;
  transition: color .2s ease;
}
#cssmenu ul ul li:hover > a,
#cssmenu ul ul li a:hover {
  color: #e57632;
}
#cssmenu ul ul li.has-sub > a::after {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 4px;
  height: 4px;
  border-bottom: 1px solid #222;
  border-right: 1px solid #222;
  content: "";
  transform: rotate(-45deg);
  transition: border-color 0.2s ease;
}
#cssmenu.align-right ul ul li.has-sub > a::after {
  right: auto;
  left: 10px;
  border-bottom: 0;
  border-right: 0;
  border-top: 1px solid #dddddd;
  border-left: 1px solid #dddddd;
}
#cssmenu ul ul li.has-sub:hover > a::after {
  border-color: #fff;
}
.home-icon{
  background: #222;
  a::after{
    content: '';
    border: none !important;
  }
  a{
    &:hover{
      color: #fff !important;
    }
    i{
      font-size: 18px;
    }
  }
}

@media screen and (max-width: 991px){
  #cssmenu {
    
    width: 100%;
    background: #22292d;
    display: none;
    position: fixed;
    min-height: 100%;
    z-index: 2000000;
        top: 0;
    left: 0;
    > ul{
    display: block !important;
    }
  }
  .uni-icons-close{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    border: solid 1px #333333;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 35px;
    i{
      font-family: FontAwesome;
      font-size: 15px;
      line-height: 2.93;
      text-align: center;
      color: #aeaeae;
    }
  }
  #cssmenu ul {
    width: 100%;
    display: none;
    padding: 0 30px;
    background: transparent;
  }
  #cssmenu ul ul{
    background: transparent;
    padding-right: 0;
  }
  #cssmenu.align-center > ul,
  #cssmenu.align-right ul ul {
    text-align: left;
  }
  #cssmenu ul li,
  #cssmenu ul ul li,
  #cssmenu ul li:hover > ul > li{
    width: 100%;
    height: auto;
    border-top: 1px solid rgba(120, 120, 120, 0.15);
  }
  #cssmenu ul ul li,
  #cssmenu ul li:hover > ul > li{
    border-top: 1px solid transparent;
  }
  #cssmenu li:hover > ul{
    border-top: 1px solid rgba(120, 120, 120, 0.15);
  }
  #cssmenu ul li a,
  #cssmenu ul ul li a {
    width: 100%;
    padding: 11px 0;
    text-transform: inherit;
  }
  #cssmenu > ul > li,
  #cssmenu.align-center > ul > li,
  #cssmenu.align-right > ul > li {
    float: none;
    display: block;
  }
  #cssmenu ul ul li {
    &:last-child{
      a{
        border-bottom: none;
      }
    }
    a {
      display:  block;
      line-height: 24px;
      font-size: 14px;
      color: #aeaeae;
      background: none;
      border: none;
      border-bottom: 1px solid rgba(120, 120, 120, 0.15);
    }
  }
  #cssmenu ul ul li:hover > a,
  #cssmenu ul ul li a:hover {
    color: #e57632;
  }
  .home-icon{
    background: transparent;
  }
  #cssmenu > ul > li:hover > a,
  #cssmenu > ul > li.active > a {
    color: #e57632 !important;
  }
  #cssmenu ul ul ul li a {
    padding-left: 0;
    border-top:  none;
  }
  #cssmenu ul ul,
  #cssmenu ul ul ul {
    position: relative;
    left: 0;
    right: auto;
    width: 100%;
    margin: 0;
    border-top: 1px solid rgba(120, 120, 120, 0.15);
  }
  #cssmenu > ul > li.has-sub > a::after,
  #cssmenu ul ul li.has-sub > a::after {
    display: none;
  }
  #menu-line {
    display: none;
  }
  #cssmenu #menu-button {
    display: block;
    padding: 20px;
    color: #000000;
    cursor: pointer;
  }
  #cssmenu .submenu-button {
    position: absolute;
    z-index: 10;
    right: 0;
    top: 0;
    display: block;
    height: 42px;
    width: 42px;
    cursor: pointer;
    border-left: 1px solid rgba(120, 120, 120, 0.15);
  }
  #cssmenu .submenu-button::after {
    content: '\f107';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    font-family: FontAwesome;
    color: #fff;
    z-index: 99;
  }
  #cssmenu .submenu-button.active::before {
    content: '\f106';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    font-family: FontAwesome;
    color: #e57632;
    z-index: 99;
  }

  #cssmenu ul li ul li .submenu-button::after{
    content: '';
    position: absolute;
    top: 21px;
    left: 20px;
    display: block;
    width: 1px;
    height: 11px;
    background: #fff;
    z-index: 99;
  }
  #cssmenu ul li ul li .submenu-button::before{
    content: '';
    position: absolute;
    left: 15px;
    top: 21px;
    display: block;
    width: 11px;
    height: 1px;
    background: #fff;
    z-index: 99;
  }
  #cssmenu ul li ul li .submenu-button.active::before {
    content: '';
    position: absolute;
    left: 21px;
    top: 21px;
    display: block;
    width: 11px;
    height: 1px;
    background: #e57632;
    z-index: 99;
  }
  #cssmenu .submenu-button.submenu-opened:after {
    display: none;
  }
  .navbar-toggle .icon-bar{
    display:  inline-block;
    i.fa{
    font-size: 20px;
    }
  }
}

/*shopping cart*/
.uni-right-menu{
  float: right;
  padding: 25px 0;
  @media screen and (max-width:991px){
    display:none;
  }
  .shopping-cart {
    float: right;
    padding-left: 40px;
    padding-right: 12px;
    position: relative;
    color: #2E3841;
    font-size: 18px;
    i{
      cursor: pointer;
      font-family: "fontello";
      font-size: 24px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: center;
      color: #22292d;
    }
    .number-item {
      font-size: 12px;
      color: #fff;
      position: absolute;
      width: 20px;
      height: 20px;
      background-color: #e57632;
      top: -3px;
      right: 6px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      border-radius: 50%;
    }
  }
}

/*MEGA MENU 1*/
.uni-mega-menu{
  position: absolute;
  width: 100%;
  top: 80px;
  z-index: 999;
  background-color: #ffffff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  border-top: 4px solid #e57632;
  display: none;
  .uni-mega-menu-1-sport{
    position: absolute;
    width: 100%;
    background: #f4f4f4;
    z-index:70000;
    display:  none;
    min-height: 330px;
    overflow: hidden;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  }
  .uni-mega-menu-left{
    width: 20%;
    float:  left;
    background: #22292d;
    min-height: 380px;
    padding: 5px 20px;
    .nav-pills>li{
      float:  none;
      a{
        transition:  all 0.5s;
        &:hover{
          color: #e57632;
          background:  transparent;
        }
      }
    }
    .nav-pills>li.active>a,
    .nav-pills>li.active>a:focus,
    .nav-pills>li.active>a:hover{
      background-color: transparent;
      color: #e57632;
    }
    ul{
      li{
        a{
          font-family: "Open Sans";
          font-size: 14px;
          text-align: left;
          color: #ffffff;
          line-height: 3.71;
          padding: 0;
          border-bottom: 1px solid #3c454a;
          border-radius: 0;
        }
        &:last-child{
          a{
            border-bottom: none;
          }
        }
      }
    }
  }
  .uni-mega-menu-right{
    background:  #fff;
    width: 80%;
    float:  left;
    min-height: 380px;
    padding:  20px;
    .item{
      padding-bottom: 20px;
      .item-img{
        position:  relative;
        overflow:  hidden;
        &:hover{
          img{
            transform: scale(1.1);
          }
        }
        img{
          width: 100%;
          height: auto;
          transition:  all 0.5s;
        }
        .item-img-caption{
          position:  absolute;
          right: 0;
          bottom: 20px;
          p{
            font-family: "proxima_nova_altbold";
            font-size: 20px;
            font-weight: bold;
            font-style: italic;
            font-stretch: normal;
            line-height: 40px;
            letter-spacing: normal;
            text-align: center;
            color: #ffffff;
            background-color: #e57632;
            padding:  0 20px;
          }
        }
      }
      .item-info{
        padding-top: 20px;
        h3{
          a{
            display:  block;
            font-family: "Proxima Nova Th";
            font-size: 18px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: 0.94;
            letter-spacing: normal;
            text-align: left;
            color: #333333;
            text-transform:  uppercase;
            transition:  all 0.5s;
            &:hover{
              color: #e57632;
            }
          }
        }
        ul{
          display:  inline-block;
          li{
            width: 50%;
            float:  left;
            margin-top:  10px;
            .left-item{              
              .left-item-info{
                padding-left: 30px;
                span{
                  font-family: "Open Sans";
                  font-size: 14px;
                  font-weight: normal;
                  font-style: normal;
                  font-stretch: normal;
                  line-height: 1.21;
                  letter-spacing: normal;
                  text-align: left;
                  color: #666666;
                }
              }
            }
          }
        }
      }
    }
    #uni-mega-menu-tab-1,
    #uni-mega-menu-tab-2,
    #uni-mega-menu-tab-3,
    #uni-mega-menu-tab-4,
    #uni-mega-menu-tab-5,
    #uni-mega-menu-tab-6,
    #uni-mega-menu-tab-7
    {
      position: relative;
      .owl-nav{
        display: block;
        margin-top: 0;
        .owl-prev{
          position: absolute;
          right: 40px;
          bottom: -40px;
          transform: translateY(-50%);
          width: 30px;
          height: 30px;
          background-color: #fff;
          border: solid 1px #eaeaea;
          padding: 0;
          @media screen and (max-width: 991px) {
            top: -5%;
          }
          &:hover{
            background-color: #e57632;
            &:after{
              color: #fff;
            }
          }
          &:after{
            content: "\f053";
            display: block;
            color: #777;
            font-family: FontAwesome;
            font-size: 14px;
            padding: 9px;
            line-height: 0.87;
            text-align: left;
            -webkit-transition: all 0.5s;
            -o-transition: all 0.5s;
            transition: all 0.5s;
          }
        }
        .owl-next{
          position: absolute;
          right: 0;
          bottom: -40px;
          transform: translateY(-50%);
          color: black;
          padding: 0;
          width: 30px;
          height: 30px;
          background-color: #fff;
          border: solid 1px #eaeaea;
          @media screen and (max-width: 991px) {
            top: -5%;
          }
          &:hover{
            background-color: #e57632;
            &:after{
              color: #fff;
            }
          }
          &:after{
            content: "\f054";
            display: block;
            color: #777;
            font-family: FontAwesome;
            font-size: 14px;
            padding: 9px;
            line-height: 0.87;
            text-align: left;
            -webkit-transition: all 0.5s;
            -o-transition: all 0.5s;
            transition: all 0.5s;
          }
        }
      }
    }
  }
}
.hstyle4 .mobile-menu-container .navbar-toggle{
  color: #fff;
}
.hmotor .mobile-menu-container .navbar-toggle{
  top: 55px;right: 15px;
}
@media screen and (max-width: 991px){
  .uni-hstyle2 .sticky-wrapper.is-sticky .vk-header-top{
    display:none;
  }
  .uni-hstyle2 .sticky-wrapper .vk-navbar-header{
  padding-top:50px;float:  left;
  }
  .uni-hstyle2 .sticky-wrapper.is-sticky .vk-navbar-header{
    padding-top:0;
  }
  .uni-hstyle2 .sticky-wrapper.is-sticky .hmotor .mobile-menu-container .navbar-toggle{
    top: 5px;
  }
  .uni-hstyle2 .sticky-menu > .vk-navbar{
    background: #fff;
  }
  .hmotor .navbar-brand{
    padding: 5px 15px;
  }
  .vk-header .sticky-wrapper.is-sticky{
    visibility: visible;
  }
  .vk-header .sticky-wrapper.is-sticky > .uni-main-menu{ z-index: 3 !important;    }
}

.uni-pocars .item .item-info ul li .left-item .left-item-icons,
.uni-popular-cars-item .item .item-info ul li .left-item .left-item-icons {
    width: 23px;
    height: 22px;
    text-align: center;
    background-color: #aeaeae;
    border-radius: 50%;
    float: left;
    padding: 0 6px;
}
.uni-pocars .item .item-info ul li .left-item .left-item-icons img,
.uni-popular-cars-item .item .item-info ul li .left-item .left-item-icons img {
    max-width: 100%;
    display:inline-block;
}
.hmotor{
  position: relative;
}
.hstyle3{
  .uni-main-menu{
      position: relative;
      .wrapper-logo{
        position: absolute;
        left: 0;
        right: 0;
        height: 81px;
        width: 230px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 23;
        border: none;
        background: #e57632;
        @media screen and (max-width: 991px) {
          position:  relative;
          align-items: left;
          justify-content: left;
        }
        &:before{
          content: '';
          position: absolute;
          left: -55px;
          width: 80px;
          height: 81px;
          background: #ebeff0;
          transform:  skew(30deg);
        }
        &:after{
          content: '';
          position: absolute;
          right: -55px;
          width: 80px;
          height: 81px;
          background: #ebeff0;
          transform:  skew(-30deg);
        }
      }
      .uni-menu-text{
        float:  none;
        .vk-navbar-left{
          position: relative;
          z-index: 999;
          float: left;
        }
        .vk-navbar-right{
          position: relative;
          z-index: 999;
          float: right;
        }
      }
      .cssmenu{
        float:  none;
        width:100%;
      }
      .uni-right-menu{
        padding: 25px 0;
        .shopping-cart{
          padding-left: 30px;
        }
      }
      .home-car-services-search{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 81px;
        .icons-search{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          border: 1px solid #22292d;
          font-family: FontAwesome;
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.14;
          letter-spacing: normal;
          text-align: center;
          color: #22292d;
          &:hover{
            cursor: pointer;
          }
          .search{
            width: 300px;
            height: 50px;
            overflow: hidden;
            position: absolute;
            margin-left: -125px;
            visibility: hidden;
            transition: all 0.5s;
            .search-field {
              width: 100%;
              height: 50px;
              margin-right: -300px !important;
              padding-left: 20px;
              border: none;
              outline: none;
              font-size: 14px;
              color: #999999;
              background: #343c40;
              text-transform: capitalize;
              float: right;
              transform-origin: right;
              transition: all 0.5s;
            }
            .close-search {
              width: 50px;
              height: 50px;
              right: 0;
              margin-top: 0;
              border: none;
              outline: none;
              position: absolute;
              z-index: 1000;
              background: #343c40;
              color: #e57632;
              display: none;
              transition: all 0.5s;
            }
            .block-search {
              margin-right: 0px !important;
              opacity: 1 !important;
              visibility: visible !important;
              transition: all 0.5s;
            }
          }
        }
      }
      @media screen and (max-width: 991px){
        background: #e57632;
      }
      > .container{
        @media screen and (max-width: 991px){
            position: relative;
          }
      }
      .mobile-menu-container{
        .navbar-toggle{
          color: #fff;right: 15px;
        }
      } 
      .wrapper-logo{

        &:before{
          @media screen and (max-width: 991px){
            content: none;
          }
        }
        &:after{
          @media screen and (max-width: 991px){
            content: none;
          }
        }
      }
    }
  
}

    