/*FAQ*/
.uni-faq-body{
  padding:  80px 0;
  h2{
    margin-bottom: 60px;
    font-family: "Proxima Nova Th";
    font-size: 36px;
    font-weight: bold;
    font-style: italic;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #22292d;
    text-transform:  uppercase;
    span{
      color: #e57632;
    }
  }
}