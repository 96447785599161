/*ITEM LISTS*/
.uni-shortcode-item-list{
  h3{
    font-family: 'Proxima Nova Th';
    font-size: 30px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #22292d;
    padding-bottom: 40px;
    margin: 0;
  }
  .uni-shortcode-item-list-body{
    padding: 100px 0;
  }
}

//UNORDERRD LIST
.uni-unordered-list{
  ul{
    &.uni-around{
      padding-left: 20px;
      li{
        list-style-type: disc;
      }
    }
    &.uni-tick{
      li{
        &:before{
          content: '\f00c';
          font-family: FontAwesome;
          font-size: 14px;
          color: #404040;
          margin-right: 10px;
        }
      }
    }
    &.uni-cancel{
      li{
        &:before{
          content: '\f00d';
          font-family: FontAwesome;
          font-size: 14px;
          color: #404040;
          margin-right: 10px;
        }
      }
    }
    li{
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      line-height: 2.14;
      text-align: left;
      color: #808080;
    }
  }
}
//ORDERED LISST
.uni-ordered-list{
  padding:60px 0;
  ul{
    &.uni-number{
      li{
        .icon{
          font-weight: bold;
          color: #000;
          margin-right: 10px;
        }
      }
    }
    &.uni-alpha{
      li{
        .icon{
          font-weight: bold;
          color: #000;
          margin-right: 10px;
        }
      }
    }
    &.uni-lama{
      li{
        .icon{
          font-weight: bold;
          color: #000;
          margin-right: 10px;
        }
      }
    }
    li{
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      line-height: 2.14;
      text-align: left;
      color: #808080;
    }
  }
}

//ICON LIST
.uni-icon-list{
  ul{
    &.uni-icon-black{
      li{
        span{
          i{
            color: #000;
            margin-right: 10px;
          }
        }
      }
    }
    &.uni-icon-yellow{
      li{
        span{
          i{
            color: #e57632;
            margin-right: 10px;
          }
        }
      }
    }
    &.uni-icon-grey{
      li{
        span{
          i{
            color: #808080;
            margin-right: 10px;
          }
        }
      }
    }
    li{
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      line-height: 2.14;
      text-align: left;
      color: #808080;
    }
  }
}