/*OUR SERVICES*/
.uni-our-services-smartcar{
  padding:  80px 0;
  .uni-our-services-smartcar-left{
    h2{
      font-family: "Proxima Nova Th";
      font-size: 28px;
      font-weight: bold;
      font-style: italic;
      font-stretch: normal;
      letter-spacing: normal;
      text-align: left;
      color: #22292d;
      text-transform: uppercase;
      span{
        color: #e57632;
      }
    }
    .uni-divider{
      border-color: #e57632;
    }
    p{
      padding-top: 20px;
      font-family: "Open Sans";
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.86;
      letter-spacing: normal;
      text-align: left;
      color: #666666;
    }
  }
  .uni-our-services-smartcar-right{
    background: url("../../images/services/1.jpg") no-repeat;
    background-size: cover;
    min-height: 360px;
    background-position: center center;
  }
}
.uni-our-services{
  padding: 80px 0 30px 0;
  .title-default{
    color: #fff;
  }
  .uni-underline{
    margin-bottom: 60px;
  }
  .item{
    padding-bottom: 50px;
    .item-img{
      img{
        width: 100%;
        height: auto;
      }
    }
    .item-info{
      padding-top: 20px;
      h3{
        font-family: "Proxima Nova Th";
        font-size: 24px;
        font-weight: bold;
        font-style: italic;
        font-stretch: normal;
        line-height: 0.71;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
        text-transform:  uppercase;
        span{
          color: #e57632;
        }
      }
      .uni-divider{
        border-color: #e57632;
      }
      p{
        padding-top: 20px;
        font-family: "Open Sans";
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.86;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;
      }
    }
  }
}

/*FULL OPTION SERVICES*/
.uni-our-services-full-option{
  padding:  80px 0;
  .uni-our-services-full-option-left{
    background: url("../../images/services/full-our-services.jpg") no-repeat;
    background-size: cover;
    background-position: center center;
    min-height: 400px;
  }
  .uni-our-services-full-option-right{
    @media screen and (max-width: 991px) {
      padding-top: 20px;
    }
    h3{
      font-family: "Proxima Nova Th";
      font-size: 28px;
      font-weight: bold;
      font-style: italic;
      font-stretch: normal;
      letter-spacing: normal;
      text-align: left;
      text-transform:  uppercase;
      color: #e57632;
      span{
        color: #22292d;
      }
    }
    .uni-divider{
      border-color: #e57632;
    }
    p{
      padding-top: 30px;
      font-family: "Open Sans";
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.86;
      letter-spacing: normal;
      text-align: left;
      color: #666666;
    }
    ul{
      li{
        font-family: "Open Sans";
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 2.57;
        letter-spacing: normal;
        text-align: left;
        color: #666666;
        i{
          color: #e57632;
        }
      }
    }
  }
}