.uni-shop-body{
  padding: 80px 0;
  background: #f4f4f4;
  @media screen and (max-width: 991px){
    padding: 30px 0;
  }
  
  .row{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .sidebar-shop{
    @media screen and (max-width: 991px){
      order: 2;
      margin-top: 60px;
      width: 100%;
    }
  }
}
//FILTER PRODUCT
.product-filter{
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
  .woocommerce-result-count{
    float: left;
    @media screen and (max-width: 767px){
      float: none;
    }
  }
  .woocommerce-ordering{
    float: right;
    @media screen and (max-width: 767px){
      float: none;
      margin-top: 20px;
    }
  }
  p{
    font-family: 'Proxima Nova Th';
    font-size: 16px;
    font-weight: bold;
    font-style: italic;
    font-stretch: normal;
    line-height: 1.06;
    letter-spacing: normal;
    text-align: left;
    color: #666666;
    text-transform: uppercase;
    margin-bottom: 0;
    padding-top: 15px;
    span{
      color: #e57632;
    }
  }
  form{
    margin-bottom: 0;
  }
  select{
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    line-height: 2.14;
    text-align: left;
    color: #666;
    width: 205px;
    height: 40px;
    border-radius: 3px;
    border: solid 1px #e0e0e0;
    padding: 0 15px;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    option{
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      line-height: 2.14;
      text-align: left;
      color: #808080;
    }
  }
}

//WAPPER PRODUCT
.uni-product-wapper{

}
.product-item{
  margin-top:  30px;
  &:hover{
    .category-product {
      li {
        .wrapper {
          box-shadow: 0px 2px 20px 0 rgba(0, 0, 0, 0.15);
          .feature-image{
            background: #fff;
          }
        }
      }
    }
  }
  .category-product{
    li{
      .wrapper{
        border-radius: 3px;
        background-color: #ffffff;
        transition: all 0.5s;
        .feature-image{
          position: relative;
          background: #fff;
          transition: all 0.5s;
          @media screen and (min-width: 992px) {
            width: 100%;
            height: 270px;
            overflow: hidden;
            img{
              width: auto;
              height:100%;
              margin: 0 auto;
              padding: 30px 0;
            }
          }
          @media screen and (max-width: 991px) {
            img{
              width: 100%;
              height:auto;
              margin: 0 auto;
              padding: 30px 0;
            }
          }
          .uni-sale{
            position: absolute;
            top: 15px;
            left: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-color: #e57632;
            box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
            span{
              font-family: 'Open Sans', sans-serif;
              font-size: 14px;
              font-weight: 600;
              line-height: 2.14;
              text-align: center;
              color: #ffffff;
            }
          }
        }
        .stats{
          padding: 20px;
          padding-top: 0px;
          .box-title{
            .title-product{
              a{
                display: block;
                font-family: 'Proxima Nova Th';
                font-size: 18px;
                font-weight: bold;
                font-style: italic;
                font-stretch: normal;
                line-height: 1.22;
                letter-spacing: normal;
                text-align: left;
                text-transform: uppercase;
                color: #22292d;
                transition: all 0.5s;
                @media screen and (max-width: 1199px) and (min-width: 992px) {
                  font-size: 14px;
                }
                &:hover{
                  color: #e57632;
                }
              }
            }
          }
          .uni-divider{
            border-color: #e57632;
          }
          .price-add-cart{
            padding-top: 20px;
            .box-price{
              float: left;
              text-align: center;
              line-height: 2.5;
              .woocommerce-Price-amount{
                font-size: 20px;
                font-weight: bold;
                font-style: italic;
                font-stretch: normal;
                line-height: 0.71;
                letter-spacing: normal;
                text-align: left;
                color: #e57632;
              }
            }
            .box-add{
              float: right;
              text-align: center;
              a{
                padding: 10px 8px;
                border: 1px solid #e57632;
                font-family: fontello;
                font-size: 16px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.06;
                letter-spacing: normal;
                text-align: center;
                color: #22292d;
                position: relative;
                border-radius: 3px;
                transition: all 0.5s;
                display: flex;
                align-items: center;
                justify-content: center;
                @media screen and (max-width: 1199px) and (min-width: 992px){
                  display: block;
                  padding: 11px 0;
                }
                &:hover{
                  background: #e57632;
                  color:#fff;
                }
              }
            }
          }
        }
      }
    }
  }
}

//PAGINATION
.loop-pagination{
  text-align: center;
  margin-top: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  li{
    float: left;
    margin-left: -1px;
    @media screen and (max-width: 991px) {
      margin-left: 1px;
    }
    &:first-child{
      margin-left: 0;
    }
    a{
      &.page-numbers {
        display: flex;
        width: 50px;
        height: 50px;
        align-items: center;
        justify-content: center;
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        line-height: 1.06;
        text-align: center;
        color: #666666;
        border: 1px solid #e0e0e0;
        padding: 10px 15px;
        transition:  all 0.5s;
        @media screen and (max-width: 767px) {
          width: 35px;
          height: 35px;
        }
        &:hover{
          background: #e57632;
          color: #fff;
          border-color: #e57632;
        }
      }
      &.current{
        background: #e57632;
        color: #fff;
        border-color: #e57632;
      }
      &.prev{
        border: 1px solid #E0E0E0;
        padding: 10px 18px;
      }
      &.next{
        border: 1px solid #E0E0E0;
        padding: 10px 18px;
      }
    }
  }
}