/*HOME CAR DEALER 1*/
.home-car-dealer{
  overflow: hidden;  
}
/*SLIDER HOME CAR DEALER*/
.vk-baner-slider{
  .vk-banner {
    position: relative;
    min-height: 160px;
    height: 160px;
    @media screen and (max-width: 991px) {
      min-height: 280px;
    }
  }
  @media (min-width: 992px) {
    .vk-banner {
      min-height: 400px;
      height: 400px; }
  }

  &.slick-dotted.slick-slider{
    margin-bottom: 0;
  }
  .page-heading{
    .slide-title-head{
      ul{
        padding-left: 50px;
        margin-bottom: 5px;
        @media screen and (max-width: 767px) {
          padding-left: 25px;
        }
        li{
          display:  inline-block;
          margin-right: 15px;
          &.status{
            display:  inline-block;
            position: relative;
            font-family: "Proxima Nova Th";
            font-size: 18px;
            font-weight: bold;
            font-style: italic;
            font-stretch: normal;
            line-height: 28px;
            letter-spacing: normal;
            text-align: left;
            text-transform:  uppercase;
            color: #ffffff;
            background-color: #e57632;
            transform: skew(-30deg);
            padding:  0 10px;
            top: 9px;
            @media screen and (max-width: 767px) {
              font-size: 10px;
            }
            span{
              display:  inline-block;
              transform: skew(30deg);
            }
          }
          &.price{
            display:  inline-block;
            font-family: "Proxima Nova Th";
            font-size: 60px;
            font-weight: bold;
            font-style: italic;
            font-stretch: normal;
            letter-spacing: normal;
            text-align: left;
            line-height: 70px;
            color: #ffffff;
            background-color: #22292d;
            transform: skew(-30deg);
            padding:  0 20px;
            @media screen and (max-width: 767px) {
              font-size: 21.2px;
              line-height: 50px;
            }
            span{
              display:  inline-block;
              transform: skew(30deg);
            }
          }
        }
      }
    }
    .slide-title-car-name{
      background-color: #fff;
      transform: skew(-30deg);
      padding: 10px 40px;
      h2{
        font-family: "Proxima Nova Th";
        font-size: 60px;
        font-weight: bold;
        font-style: italic;
        font-stretch: normal;
        line-height: 0.9;
        letter-spacing: normal;
        text-align: left;
        text-transform:  uppercase;
        color: #22292d;
        transform: skew(30deg);
        @media screen and (max-width: 767px) {
          display:  inline-block;
          font-size: 22px;
        }
        span{
          color: #e57632;
        }
      }
    }
    .slide-title-car-rate{
      margin-top: 10px;
      h3{
        font-family: "Proxima Nova Th";
        font-size: 56px;
        font-weight: bold;
        font-style: italic;
        font-stretch: normal;
        letter-spacing: normal;
        text-align: left;
        text-transform:  uppercase;
        color: #ffffff;
        @media screen and (max-width: 767px) {
          font-size: 20px;
        }
        span{
          color: #e57632;
        }
      }
    }
    .slide-btn-learn-more{
      float: right;
      background-color: #22292d;
      transform: skew(-30deg);
      padding: 0 15px;
      transition:  all 0.5s;
      @media screen and (min-width: 992px) {
        margin-right: 85px;
      }
      @media screen and (max-width: 767px) {
        margin-right: 40px;
      }
      &:hover{
        background: #fff;
        a{
          color: #e57632;
        }
      }
      a{
        display: block;
        font-family: "Proxima Nova Th";
        font-size: 18px;
        font-weight: 500;
        font-style: italic;
        font-stretch: normal;
        line-height: 44px;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;
        text-transform:  uppercase;
        transform: skew(30deg);
        @media screen and (max-width: 767px) {
          font-size: 10px;
          line-height: 30px;
        };
        span{
          font-family: "Proxima Nova Th";
          font-style: italic;
          font-weight: 900;
          color: #e57632;
        }
      }
    }
  }
  .vk-banner .wrapper {
    min-height: inherit;
    height: inherit;
    display: flex;
    align-items: center;
    padding-left: 210px;
    @media screen and (max-width: 767px) {
      padding-left:  50px;
    }
  }
  @media (min-width: 992px) {
    .vk-banner-large {
      min-height: 800px;
      height: 800px; } }
  .vk-banner-homepage {
    min-height: 0;
    height: auto; }
  .vk-banner-homepage .vk-background-image img {
    width: 100%; }
  .vk-banner-homepage .content {
    position: absolute;
    bottom: 50%;
    -webkit-transform: translateY(50%);
    -moz-transform: translateY(50%);
    -ms-transform: translateY(50%);
    -o-transform: translateY(50%);
    transform: translateY(50%);
    width: 100%; }
  @media (min-width: 992px) {
    .vk-banner-homepage .page-heading {
      position: static; } }
  .vk-banner-mod {
    height: 100%; }
  .vk-banner-mod .page-heading {
    text-transform: none; }
  .vk-banner-mod .title-main {
    font-size: 36px;
    line-height: 40px; }
  @media (min-width: 992px) {
    .vk-banner-mod .title-main {
      font-size: 80px;
      line-height: normal; } }
  .vk-banner-mod .title-sub {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 5px;
    margin-top: 20px;
    line-height: 26px; }
  @media (min-width: 992px) {
    .vk-banner-mod .title-sub {
      font-size: 18px;
      letter-spacing: 9px;
      margin-top: 37px; } }
  .vk-arrow {
    width: 60px;
    height: 60px;
    font-size: 30px;
    padding: 0;
    background-color: rgba(0, 0, 0, 0.3);
    border: none;
    border-radius: 50%;
    color: #8D8D8D;
    position: absolute;
    z-index: 1;
    top: 45%;
    min-height: 0;
    outline: none;
    overflow: visible;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .vk-arrow .image-preview-thumbnail {
    height: 60px;
    width: 0px;
    display: block;
    border-radius: 50%;
    position: absolute;
    top: 0;
    background-size: cover;
  }
  .vk-arrow.prev {
    left: 60px; }
  .vk-arrow.prev .image-preview-thumbnail {
    left: 0; }
  .vk-arrow.next {
    right: 60px; }
  .vk-arrow.next .image-preview-thumbnail {
    right: 0; }
  .vk-arrow .icon {
    position: relative;
    top: 3px; }
  .vk-arrow:hover .image-preview-thumbnail, .vk-slider-arrow-image .vk-arrow:focus .image-preview-thumbnail {
    width: 60px; }

  .vk-dots-nav {
    position: absolute;
    bottom: 35px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    @media screen and (max-width: 991px) {
      bottom: 20px;
    }
  }
  .vk-dots-nav li {
    float: left;
    width: 25px;
    height: 6px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    transform: skew(-40deg);
  }
  .vk-dots-nav li button {
    display: none; }
  .vk-dots-nav li:before {
    content: '';
    width: 25px;
    height: 6px;
    background-color: #E0E0E0;
    position: absolute;
    left: 5px;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    display: block; }
  .vk-dots-nav li.slick-active:before, .vk-dots-nav li:hover:before {
    background-color: #e57632; }
  .slick-dotted.slick-slider{
    margin-bottom: 0;
  }
}

/*SEARCH MY CAR*/
.uni-search-my-car{
  background-color: #22292d;
  padding:  60px 0 30px 0;
  @media screen and (max-width:991px){
    padding:  60px 0 60px 0;
  }
  .title-default{
    color: #fff;
  }
  .uni-underline{
    margin-bottom: 60px;
  }
  .ui.selection.dropdown{
    width: 100%;
    min-height: 44px;
    border-radius: 4px;
    background-color: #343c40;
    margin-bottom:  30px;
    line-height:44px;
    padding-top:0;
    padding-bottom:0;
    i{
      float:  right;
      line-height:44px;
    }
    .text{
      font-family: "Open Sans";
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.21;
      letter-spacing: normal;
      text-align: left;
      color: #aeaeae;
      text-transform: uppercase;
    }
    .item{
      text-transform:  capitalize;
    }
  }
  .btn-search-car{
    width: 100%;
    height: 44px;
    border-radius: 4px;
    background-color: #e57632;
    border: 1px solid #e57632;
    font-family: "Proxima Nova Th";
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: center;
    text-transform:  uppercase;
    color: #ffffff;
    transition:  all 0.5s;
    &:hover{
      background-color: #343c40;
    }
  }
}

/*POPULAR CARS*/
.uni-popular-cars{
  padding:  100px 0;
  .uni-underline{
    margin-bottom: 40px;
  }
  .uni-popular-cars-body{
    .uni-popular-cars-item{
      width: 100%;
      display: inline-block;
    }
    .uni-popular-cars-menu{
      margin-bottom: 60px;
      ul{
        text-align: center;
        li{
          display:  inline-flex;
          font-family: "Proxima Nova Th";
          font-size: 14px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: 30px;
          letter-spacing: normal;
          text-align: center;
          color: #666666;
          border-radius: 4px;
          padding:  0 15px;
          background-color: #e0e0e0;
          transition:  all 0.5s;
          text-transform: uppercase;
          margin-right: 5px;
          &.active{
            background: #e57632;
            color: #fff;
          }
          &:hover{
            background: #e57632;
            color: #fff;
            cursor:  pointer;
          }
        }
      }
    }
    .item{
      padding-bottom: 40px;
      .item-img{
        position:  relative;
        overflow:  hidden;
        &:hover{
          img{
            transform: scale(1.1);
          }
        }
        img{
          width: 100%;
          height: auto;
          transition:  all 0.5s;
        }
        .item-img-caption{
          position:  absolute;
          right: 0;
          bottom: 20px;
          p{
            font-size: 20px;
            font-weight: bold;
            font-style: italic;
            font-stretch: normal;
            line-height: 40px;
            letter-spacing: normal;
            text-align: center;
            color: #ffffff;
            background-color: #e57632;
            padding:  0 20px;
            font-family: "proxima_nova_altbold";
          }
        }
      }
      .item-info{
        padding-top: 20px;
        h3{
          a{
            display:  block;
            font-family: "Proxima Nova Th";
            font-size: 18px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: 0.94;
            letter-spacing: normal;
            text-align: left;
            color: #333333;
            text-transform:  uppercase;
            transition:  all 0.5s;
            &:hover{
              color: #e57632;
            }
          }
        }
        ul{
          display:  inline-block;
          li{
            width: 50%;
            float:  left;
            margin-top:  10px;
            .left-item{
              .left-item-icons{                
                width: 23px;
                height: 22px;
                text-align:center;
                background-color: #aeaeae;
                border-radius: 50%;
                float:  left;
                padding: 0 6px;
                img{
                max-width: 100%;
                }
              }
              .left-item-info{
                padding-left: 30px;
                span{
                  font-family: "Open Sans";
                  font-size: 14px;
                  font-weight: normal;
                  font-style: normal;
                  font-stretch: normal;
                  line-height: 1.21;
                  letter-spacing: normal;
                  text-align: left;
                  color: #666666;
                }
              }
            }
          }
        }
      }
    }
  }
}
.uni-show-all{
  text-align: center;
  margin-top: 30px;
  .btn-show-all{
    min-width: 160px;
    height: 50px;
    border-radius: 4px;
    border: solid 1px #22292d;
    background:  #fff;
    font-family: "Proxima Nova Th";
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: center;
    color: #22292d;
    text-transform: uppercase;
    transition:  all 0.5s;
    span{
      color: #e57632;    margin-left: 5px;
    }
    &:hover{
      border-color:  #e57632;
      color: #e57632;
    }
    @media screen and (max-width:480px){
      min-width: 124px;
    }
  }
}

/*DO YOU WANT TO SELL A CAR*/
.uni-do-you-want-sell{
  padding: 120px 0;
  height: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .uni-icons{
    @media screen and (max-width: 991px) {
      display: none;
    }
  }
  h2{
    font-family: "Proxima Nova Th";
    font-size: 36px;
    font-weight: bold;
    font-style: italic;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    text-transform: uppercase;
    span{
      color: #e57632;
    }
  }
  p{
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.86;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    margin:  30px 0;
  }
  ul{
    li{
      display:  inline-block;
      margin-left: 30px;
      @media screen and (max-width: 991px) {
        margin-top: 20px;        
      }
      @media screen and (max-width: 575px){
        width: 100%;
        margin-left: 0;      
      }
      &:first-child{
        margin-left:  0;
        @media screen and (max-width: 991px) {
          margin-top: 0;
        }
      }
      a{
        display:  block;
        font-family: "Proxima Nova Th";
        line-height: 50px;
        letter-spacing: 1px;
        text-align: center;
        color: #ffffff;
        text-transform: uppercase;
        border-radius: 4px;
        border: solid 1px #e57632;
        min-width: 160px;
        transition:  all 0.5s;
        i{
          color: #e57632;
          padding-left: 10px;
          transition:  all 0.5s;
        }
        &:hover{
          background: #e57632;
          i{
            color: #fff;
          }
        }
      }
      h4{
        font-family: "Proxima Nova Th";
        font-size: 24px;
        font-weight: bold;
        font-style: italic;
        font-stretch: normal;
        line-height: 0.71;
        letter-spacing: normal;
        text-transform: uppercase;
        text-align: left;
        color: #e57632;
        span{ color: #fff;}
        @media screen and (max-width: 575px){
          text-align: center; 
        }
      }
    }
  }
}

/*FEARTURE CAR*/
.uni-feature-car{
  padding:  100px 0;
  .uni-underline{
    margin-bottom: 60px;
  }
  .uni-feature-car-body{
    @media screen and (max-width: 991px) {
      padding:  0 15px;
    }
    .item{
      .item-img{
        position: relative;
        background-position: center center;
        background-size: 100%;
        background-repeat: no-repeat;
        width: 100%;
        min-height: 410px;
        @media screen and (max-width: 1199px) and (min-width: 992px) {
          min-height: 460px;
        }
        @media screen and (max-width: 991px) {
          min-height: 320px;
        
        }
        @media screen and (max-width: 575px) {
          min-height: 247px;
        
        }
        @media screen and (max-width: 375px) {
          min-height: 156px;
        
        }
        
        &:before{
          content: '';
          position:  absolute;
          background-color: rgba(255, 255, 255, 0.8);
          width: 100%;
          height: 100%;
        }
      }
      .item-caption{
        margin-top: 40px;
        opacity:  0;
        ul{
          li{
            width: 20%;
            float:  left;
            @media screen and (max-width: 767px) {
              width: 50%;
              margin-bottom: 20px;
            }
            .left-item{
              .left-item-icons{

                float:  left;
                @media screen and (max-width:1199px) {
                  float:none;margin: 0 auto;
                }
                width: 44px;
                height: 44px;
                background-color: #aeaeae;
                border-radius: 50%;
                position: relative;
                img{
                    width: auto;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%,-50%);
                    text-align: center;
                    max-width: 55%;
                }
              }
              .left-item-info{
                padding-left: 55px;
                @media screen and (max-width:1199px) {
                  padding-left: 0;text-align: center;    margin-top: 5px;
                }
                span{
                  font-family: "Proxima Nova Th";
                  font-size: 12px;
                  font-weight: bold;
                  font-style: normal;
                  font-stretch: normal;
                  line-height: 1.21;
                  letter-spacing: normal;
                  text-align: left;
                  text-transform: uppercase;
                  color: #aeaeae;
                }
                p{
                  font-size: 14px;
                  font-weight: bold;
                  font-style: normal;
                  font-stretch: normal;
                  line-height: 1.21;
                  letter-spacing: normal;
                  text-align: left;
                  text-transform: uppercase;
                  color: #22292d;
                  @media screen and (max-width:1199px) {
                    text-align: center;
                    margin-top: 5px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .owl-item{
      &.active{
        background-color: rgba(255, 255, 255, 0.8);
        &.center{
          .item-img{
            &:before{
              content: '';
              background: transparent;
            }
          }
          .item-caption{
            opacity: 1;
          }
        }
      }
    }
    .owl-theme .owl-dots .owl-dot span{
      width: 25px;
      height: 6px;
      background-color: #e0e0e0;
      border-radius: 0;
      margin: 5px 3px;
      transform: skew(-40deg);
    }
    .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{
      background: #e57632;
    }
    .owl-theme .owl-dots{
      margin-top: 60px;
    }
    .owl-nav {
      @media screen and (max-width: 991px) {
        display: none;
      }
      .owl-prev{
        position: absolute;
        left: 349px;
        top: 200px;
        transform: translateY(-50%);
        padding: 0;
        margin:  0;
        z-index: 123;
        width: 60px;
        height: 60px;
        border-radius:  0;
        background-color: #22292d;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.5s ;
        &:hover{
          background: #e57632;
        }
        @media screen and (max-width: 1199px) and (min-width: 992px) {
          left: 20px;
          bottom: -20%;
        }
        &:after{
          content: "\f053";
          display: block;
          color: #fff;
          font-family: FontAwesome;
          font-size: 16px;
          padding: 9px;
          line-height: 0.87;
          text-align: left;
        }
      }
      .owl-next{
        position: absolute;
        right: 348px;
        top: 200px;
        transform: translateY(-50%);
        padding: 0;
        z-index: 123;
        margin:  0;
        width: 60px;
        height: 60px;
        border-radius:  0;
        background-color: #22292d;
        display: flex;
        align-items: center;
        justify-content: center;
        transition:  all 0.5s;
        &:hover{
          background: #e57632;
        }
        @media screen and (max-width: 1199px) and (min-width: 992px) {
          right: 20px;
          bottom: -20%;
        }
        &:after{
          content: "\f054";
          display: block;
          color: #fff;
          font-family: FontAwesome;
          font-size: 16px;
          padding: 9px;
          line-height: 0.87;
          text-align: left;
        }
      }
    }
  }
}

/*WHY CHOOSE US AMD CLIEMT SAY*/
.uni-why-choose-us-client-say{
  position:  relative;
  height: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  &:before{
    content: '';
    background-color: rgba(229, 118, 50, 0.95);
    position: absolute;
    top: 0;
    left:  0;
    width: 50%;
    min-height: 100%;
    @media screen and (max-width: 991px) {
      background:  transparent;
    }
  }
  &:after{
    content: '';
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 0;
    right:  0;
    width: 50%;
    min-height: 100%;
    @media screen and (max-width: 991px) {
      background:  transparent;
    }
  }
  /*wwhy choose us*/
  .uni-why-choose-us{
    position: relative;
    @media screen and (min-width: 992px) {
    z-index:999;
    }
    padding-left: 240px;
    padding-right: 100px;
    padding-top: 60px;
    padding-bottom: 20px;
    @media screen and (min-width: 1200px) and (max-width: 1500px) {
      padding-left: 100px;
    }
    @media screen and (max-width: 1600px) and (min-width: 1501px) {
      padding-left: 150px;
    }
    @media screen and (max-width: 1199px) and (min-width:992px) {
      padding-left: 20px;
      padding-right: 20px;
    }
    @media screen and (max-width: 991px) {
      padding-left: 15px;
      padding-right: 15px;
      background-color: rgba(229, 118, 50, 0.95);
    }
    .uni-why-choose-us-title{
      margin-bottom: 30px;
      .title-default{
        text-align:  left;
        span{
          color: #fff;
        }
      }
      .uni-underline-left{
        background: url("../../images/about/underline.png") no-repeat;
        height: 16px;
      }
    }
    p{
      font-family: "Open Sans";
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.86;
      letter-spacing: normal;
      text-align: left;
      color: #22292d;
    }
    h4{
      font-family: "Proxima Nova Th";
      font-size: 18px;
      font-weight: bold;
      font-style: italic;
      font-stretch: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: left;
      color: #22292d;
      text-transform:  uppercase;
      margin-bottom:  10px;
      span{
        color: #fff;
      }
    }
    .uni-why-choose-us-des{
      margin-bottom:  50px;
    }
    .uni-why-choose-us-info{
      ul{
        li{
          display:  block;
          margin-bottom: 30px;
          .icons{
            display:  flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            height: 60px;
            border-radius: 4px;
            background-color: #22292d;
            float:  left;
          }
          .title-info{
            padding-left: 80px;
          }
        }
      }
    }
  }

  /*CLIENT SAY*/
  .uni-client-say{
    position: relative;
    @media screen and (min-width: 992px) {
    z-index:999;
    }
    
    padding-right: 240px;
    padding-left:  80px;
    padding-top: 60px;
    padding-bottom: 20px;
    @media screen and (min-width: 1200px) and (max-width: 1500px) {
      padding-right: 100px;
    }
    @media screen and (max-width: 1600px) and (min-width: 1501px) {
      padding-right: 150px;
    }
    @media screen and (max-width: 1199px) and (min-width:992px) {
      padding-left: 20px;
      padding-right: 20px;
    }
    @media screen and (max-width: 991px) {
      padding-left: 15px;
      padding-right: 15px;
      background-color: rgba(0, 0, 0, 0.8);
    }
    .uni-client-say-title{
      margin-bottom: 60px;
      .title-default{
        text-align:  left;
        color: #e57632;
        span{
          color: #fff;
        }
      }
      .uni-underline-left{
        background: url("../../images/about/underline1.png") no-repeat;
        height: 16px;
      }
    }
    .uni-client-say-body{
      position:  relative;
      .testimonial_para{
        color: #fff;
      }
      h4{
        color: #fff;
      }
      .uni-testimonial-default{
        padding-right: 2px;
      }
      .owl-theme .owl-dots .owl-dot span{
        width: 25px;
        height: 6px;
        background-color: #e0e0e0;
        border-radius: 0;
        margin: 5px 3px;
        transform: skew(-40deg);
      }
      .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{
        background: #e57632;
      }
      .owl-theme .owl-dots{
        position: absolute;
        top: -50px;
        right: 0;
      }
      .owl-nav {
        @media screen and (max-width: 991px) {
          display: none;
        }
        .owl-prev{
          position: absolute;
          right: 57px;
          top: -110px;
          transform: translateY(-50%);
          padding: 0;
          margin:  0;
          z-index: 123;
          width: 44px;
          height: 44px;
          border-radius:  4px;
          background-color: transparent;
          border: 1px solid #e57632;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.5s ;
          &:hover{
            background: #e57632;
          }
          @media screen and (max-width: 1199px) and (min-width: 992px) {
            right: 50px;
            bottom: -20%;
          }
          &:after{
            content: "\f053";
            display: block;
            color: #fff;
            font-family: FontAwesome;
            font-size: 16px;
            padding: 9px;
            line-height: 0.87;
            text-align: left;
          }
        }
        .owl-next{
          position: absolute;
          right: 0;
          top: -110px;
          transform: translateY(-50%);
          padding: 0;
          z-index: 123;
          margin:  0;
          width: 44px;
          height: 44px;
          border-radius:  4px;
          background-color: transparent;
          border: 1px solid #e57632;
          display: flex;
          align-items: center;
          justify-content: center;
          transition:  all 0.5s;
          &:hover{
            background: #e57632;
          }
          @media screen and (max-width: 1199px) and (min-width: 992px) {
            right: 0;
            bottom: -20%;
          }
          &:after{
            content: "\f054";
            display: block;
            color: #fff;
            font-family: FontAwesome;
            font-size: 16px;
            padding: 9px;
            line-height: 0.87;
            text-align: left;
          }
        }
      }
    }
  }
}

/*LATEST POST*/
.uni-latest-post{
  padding: 100px 0;
  .uni-latest-post-title{
    margin-bottom: 60px;
  }
  .uni-latest-post-body-desktop{
    @media screen and (min-width: 992px) {
      display: block;
    }
    @media screen and (max-width: 991px) {
      display: none;
    }
  }
  .uni-latest-post-body-mobiles{
    @media screen and (min-width: 992px) {
      display: none;
    }
    @media screen and (max-width: 991px) {
      display: block;
    }
  }
  .owl-theme .owl-dots .owl-dot span{
    width: 25px;
    height: 6px;
    background-color: #e0e0e0;
    border-radius: 0;
    margin: 5px 3px;
    transform: skew(-40deg);
  }
  .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{
    background: #e57632;
  }
  .owl-theme .owl-dots{
    margin-top: 60px;
  }
}

/*LOGO PARTNER*/
.uni-logo-partner{
  background-color: #f4f4f4;
  padding: 40px 0;

  .uni-logo-partner-desktop{
    @media screen and (max-width: 991px) {
      display:  none;
    }
    @media screen and (min-width: 992px) {
      display: block;
    }
  }
  .uni-logo-partner-mobiles{
    @media screen and (max-width: 991px) {
      display:  block;
    }
    @media screen and (min-width: 992px) {
      display: none;
    }
  }
}