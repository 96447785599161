/*ICONS BOXES*/
.uni-shortcode-icon-boxes{
  .uni-shortcode-icon-boxes-body{

  }
}

.uni-shortcode-icon-boxes-default{
  padding-top: 80px;
  .item{
    padding-bottom: 80px;
    .item-icons{
      display: flex;
      align-items: center;
      justify-content: center;
      float: left;
      width: 60px;
      height: 60px;
      border-radius: 4px;
      border: solid 1px #e57632;
    }
    .item-caption{
      padding-left: 80px;
      h4{
        font-family: 'Proxima Nova Rg';
        font-size: 18px;
        font-weight: bold;
        font-style: italic;
        font-stretch: normal;
        line-height: 0.94;
        letter-spacing: normal;
        text-align: left;
        color: #e57632;
        text-transform: uppercase;
        span{
          color: #22292d;
        }
      }
      p{
        font-family: "Open Sans";
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.86;
        letter-spacing: normal;
        text-align: left;
        color: #666666;
      }
    }
  }
}
.uni-shortcode-icon-boxes-nobackground{

}
.uni-shortcode-icon-boxes-background{
  background-position: center center;
  background-size: cover;
  .item{
    .item-caption{
      h4{
        span{
          color: #fff;
        }
      }
      p{
        color: #fff;
      }
    }
  }
}