/*  IVENTORY LIST*/
.uni-iventory-list-body{
  padding:  80px 0;
}
.uni-inventory-list-search-car{
  margin-bottom: 30px;
  background-color: #22292d;
  padding: 40px 20px;
  h2{
    font-family: 'Proxima Nova Th';
    font-size: 30px;
    font-weight: bold;
    font-style: italic;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    span{
      color: #e57632;
    }
  }
  h3{
    font-family: 'Proxima Nova Th';
    font-size: 14px;
    font-weight: bold;
    font-style: italic;
    font-stretch: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    text-transform: uppercase;
    color: #ffffff;
    padding-top: 20px;
  }
  .uni-divider{
    border-color: #e57632;
  }
  ul{
    padding-top: 30px;
    li{
      margin-bottom: 20px;
      &:hover{
        padding-bottom: 0;
      }
      .selection.dropdown{
        width: 100%;
        min-height: 44px;
        border-radius: 4px;
        background-color: #343c40;
        line-height: 1.5;
        i{
          float: right;
          line-height: 20px;
        }
        .text{
          font-family: "Open Sans";
          font-size: 14px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.21;
          letter-spacing: normal;
          text-align: left;
          color: #aeaeae;
        }
      }
    }
  }
  #slider-range{
    width: 100%;
    height: 5px;
    background: #E0E0E0;
    border-radius: 2px;
    border: none;
    margin-top: 20px;
  }
  .ui-state-active{
    border-color: transparent;
    background: transparent;
  }
  .ui-state-default{
    position: relative;
    border-color: transparent;
    background: transparent;
    outline: none;
    &:before{
      content: '';
      width: 16px !important;
      height: 16px !important;
      background: #e57632 !important;
      border: none;
      border-radius: 50%;
      outline: none;
      top: -2px;
      position: absolute;
    }
    &:after{
      content: '';
      width: 10px !important;
      height: 10px !important;
      background: #fff !important;
      border: none;
      border-radius: 50%;
      outline: none;
      top: 1px;
      left: 3px;
      position: absolute;
    }
  }
  .ui-slider-horizontal .ui-slider-range{
    background: #e57632;
    margin-left: -5px;
  }
  .label-filter-price{
    font-size: 14px;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: center;
    color: #666666;
    margin-top: 25px;
    input{
      font-family: 'Proxima Nova Th';
      font-size: 14px;
      font-weight: bold;
      font-style: italic;
      font-stretch: normal;
      line-height: 1.21;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
      border: none;
      background: transparent;
    }
  }

  .btn-filter-prince{
    width: 100%;
    height: 44px;
    border: none;
    outline: none;
    background: #e57632;
    font-family: 'Proxima Nova Th';
    font-weight: bold;
    font-style: normal;
    font-size: 14px;
    border-radius: 3px;
    color: #FFF;
    position: relative;
    margin-top: 25px;
    transition:all 0.5s;
    &:hover{
      border:1px solid #e57632;
      background: none;
      color: #e57632;
      transition:all 0.5s;
    }
  }
}

.uni-inventory-list-filter{
  padding-bottom: 20px;
  p{
    font-family: 'Proxima Nova Th';
    font-size: 16px;
    font-weight: bold;
    font-style: italic;
    font-stretch: normal;
    line-height: 1.06;
    letter-spacing: normal;
    text-align: left;
    color: #666666;
    float:  left;
    margin-bottom: 0;
    padding-top: 15px;
    @media screen and (max-width: 767px) {
      float:  none;
    }
    span{
      color: #e57632;
    }
  }
}
.uni-inventory-list-ordering{
  float:  right;
  @media screen and (max-width: 767px) {
    float:  none;
    margin-top: 20px;
  }
  select{
    width: 200px;
    height: 40px;
    border-radius: 4px;
    background-color: #f0f0f0;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #666666;
    padding: 0 10px;
    border-color: transparent;
  }
  a{
    display: inline-block;
    width: 40px;
    line-height: 40px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: center;
    color: #aeaeae;
    transition:  all 0.5s;
    &:hover{
      background-color: #f0f0f0;
      color: #22292d;
    }
    &.active{
      background-color: #f0f0f0;
      color: #22292d;
    }
  }
}
.uni-inventory-list-tag{
  ul{
    margin-top: 20px;
    margin-bottom: 30px;
    li{
      display:  inline-block;
      border-radius: 4px;
      background-color: #f0f0f0;
      padding: 0 20px;
      margin-right: 10px;
      p{
        font-family: "Open Sans";
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 40px;
        letter-spacing: normal;
        text-align: left;
        color: #666666;
        float: left;
        margin-bottom:  0;
        span{
          color: #e57632;
        }
      }
      i{
        font-size: 14px;
        color: #aeaeae;
        float: right;
        line-height: 40px;
        padding-left: 20px;
        &:hover{
          color: #e57632;
          cursor:  pointer;
        }
      }
    }
  }
}

.uni-inventory-list-right-content{
  .item{
    margin-bottom: 45px;
    .item-img{
      overflow: hidden;
      img{
        width: 100%;
        height: auto;
        transition:  all 0.5s;
        &:hover{
          transform: scale(1.1);
        }
      }
    }
    .item-caption{
      .item-caption-top{
        position:  relative;
        display: flex;
        @media screen and (max-width: 991px){
          margin-top: 10px;
        }
        h2{
          margin-bottom: 10px;
          a{
            font-family: 'Proxima Nova Th';
            font-size: 24px;
            font-weight: bold;
            font-style: italic;
            font-stretch: normal;
            line-height: 0.71;
            letter-spacing: normal;
            text-align: left;
            color: #22292d;
            text-transform:  uppercase;
            transition:  all 0.5s;
            @media screen and (max-width: 767px) {
              font-size: 18px;
            }
            &:hover{
              color: #e57632;
            }
          }
        }
        .item-prices{
          position: absolute;
          z-index: 3;
          top: -10px;
          right: 0;
          padding-left: 20px;
          background: #e57632;
          min-height: 50px;
          min-width: 100px;
          color: white;
          @media screen and (max-width: 767px) {
            display:  none;
          }
          &:before{
            background: #e57632 none repeat scroll 0 0;
            content: "";
            min-height: 50px;
            position: absolute;
            left: -25px;
            transform: skew(-35deg);
            width: 50px;
            border-right: 5px solid #e57632;
          }
          span{
            position: relative;
            z-index: 3;
            font-family: 'proxima_nova_altbold';
            font-size: 20px;
            font-weight: bold;
            font-style: italic;
            font-stretch: normal;
            line-height: 50px;
            letter-spacing: normal;
            text-align: center;
            color: #ffffff;
            left: -20px;
          }
        }
      }
      .item-caption-body{
        padding: 28px 0;
        .item-caption-body-left{
          ul{
            @media screen and (max-width: 991px) {
              display: flow-root;
            }
            li{
              width: 50%;
              float: left;
              margin-bottom: 20px;
              &:nth-child(3), &:nth-child(4){
                margin-bottom: 0;
                @media screen and (max-width: 991px) {
                  margin-bottom: 20px;
                }
              }
              .left-item{    
                .left-item-icons{
                  margin-top: 5px;
                }            
                .left-item-info{
                  padding-left: 30px;
                  span{
                    font-family: 'Proxima Nova Th';
                    font-size: 12px;
                    line-height: 1.42;
                    color: #aeaeae;
                    font-weight: bold;
                    font-style: normal;
                    font-stretch: normal;
                    letter-spacing: normal;
                    text-align: left;
                    text-transform:  uppercase;
                  }
                  p{
                    font-family: 'Proxima Nova Th';
                    font-size: 14px;
                    font-weight: bold;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.21;
                    letter-spacing: normal;
                    text-align: left;
                    color: #22292d;
                    text-transform:  uppercase;
                  }
                }
              }
            }
          }
        }
        .item-caption-body-right{
          p{
            font-family: "Open Sans";
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 40px;
            border-radius: 4px;
            background-color: #f0f0f0;
            letter-spacing: normal;
            text-align: center;
            color: #22292d;
            span{
              color: #666666;
            }
          }
          a{
            display:  block;
            font-family: "Open Sans";
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 40px;
            letter-spacing: normal;
            text-align: center;
            color: #22292d;
            border-radius: 4px;
            border: solid 1px #e57632;
            transition:  all 0.5s;
            &:hover{
              color: #fff;
              background-color: #e57632;
            }
          }
        }
      }
    }
  }
}