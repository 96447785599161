/*single car 1*/
.uni-single-car-body{
  padding: 80px 0;
}

.uni-single-car-left{
  h2{
    font-family: 'Proxima Nova Th';
    font-size: 36px;
    font-weight: bold;
    font-style: italic;
    font-stretch: normal;
    line-height: 0.72;
    letter-spacing: normal;
    text-align: left;
    text-transform: uppercase;
    color: #22292d;
  }
  .uni-single-car-left-top{
    padding:  30px 0;
    ul{
      li{
        display:  inline-block;
        margin-right: 10px;
        @media screen and (max-width: 1199px) {
          margin-bottom: 15px;
        }
        &:last-child{
          margin-right:  0;
        }
        a{
          display: block;
          font-family: "Open Sans";
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 40px;
          letter-spacing: normal;
          text-align: center;
          color: #343c40;
          border-radius: 4px;
          border: solid 1px #e57632;
          text-transform: uppercase;
          padding: 0 30px;
          transition: all 0.5s;
          &:hover{
            color: #fff;
            background: #e57632;
            i{
              color: #fff;
            }
          }
          i{
            color: #e57632;
            padding-right: 10px;
            transition: all 0.5s;
          }
        }
        p{
          font-family: "Open Sans";
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 40px;
          letter-spacing: normal;
          text-align: center;
          color: #22292d;
          border-radius: 4px;
          background-color: #f0f0f0;
          padding: 0 30px;
          text-transform: uppercase;
          span{
            color: #666666;
          }
        }
      }
    }
  }
  .uni-single-car-gallery-images{
    position:  relative;
    .slick-dots{
      display:  none !important;
    }
    .slide-item{
      img{
        width: 100%;
        height: auto;
      }
    }
    .slick-list{
      @media screen and (max-width: 1199px) and (min-width: 992px) {
        margin-top: 20px;
      }
    }
    .slide-item-nav{
      padding: 0 5px;
      margin-top: 10px;
      &.slick-active{
        position: relative;
        &:after{
          content: '';
          position: absolute;
          width: 94%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          top: 0;
        }
      }
      &.slick-current{
        &:after{
          content: '';
          background:  transparent;
        }
        img{
          border: solid 3px #e57632;
        }
      }
    }
    .slick-next, .slick-prev{
      width: 40px;
      height: 80px;
      background-color: rgba(0, 0, 0, 0.5);
      top: -300px;
      @media screen and (max-width: 1199px) and (min-width: 992px) {
        top: -200px;
      }
      @media screen and (max-width: 991px) {
        display:  none !important;
      }
    }
    .slick-prev{
      left:  0;
      &:before{
        content: '\f053';
        font-family: FontAwesome;
        color: #fff;
      }
    }
    .slick-next{
      right:  0;
      &:before{
        content: '\f054';
        font-family: FontAwesome;
        color: #fff;
      }
    }
  }
}
.uni-single-car-left-tabs{
  .uni-shortcode-tabs-default{
    .uni-shortcode-tab-1{
      ul{
        li{
          width: 25%;
          a{
            padding: 10px 0;
            text-align: center;
          }
        }
      }
    }
    .tab-content{
      padding-top: 40px !important;
      h4{
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 0.94;
        letter-spacing: normal;
        text-align: left;
        text-transform:  uppercase;
        color: #22292d;
        @media screen and (max-width: 991px) {
          margin-top: 20px;
        }
        span{
          color: #e57632;
          padding-right: 10px;
        }
      }
      p{
        font-family: "Open Sans";
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.86;
        letter-spacing: normal;
        text-align: left;
        color: #666666;
      }
      ul{
        li{
          width: 100%;
          font-family: "Open Sans";
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 2.57;
          letter-spacing: normal;
          text-align: left;
          color: #666666;
          i{
            color: #e57632;
            padding-right: 5px;
          }
        }
      }
      .techology{
        table{
          width: 100%;
          tr{
            td{
              border-bottom: 1px solid #E0E0E0;
              &:first-child{
                font-family: "Open Sans";
                font-size: 14px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 3.57;
                letter-spacing: normal;
                text-align: left;
                text-transform:  uppercase;
                color: #666666;
              }
              &:last-child{
                font-family: "Open Sans";
                font-size: 14px;
                font-weight: bold;
                font-style: normal;
                font-stretch: normal;
                line-height: 3.57;
                letter-spacing: normal;
                text-align: right;
                color: #22292d;
              }
            }
          }
        }
        .uni-engine{

        }
        .performance{
          margin-top: 40px;
        }
        .transmission{
          margin-top: 40px;
        }
      }
      /*feature option*/
      .feature-option{
        p{
          margin-bottom: 40px;
        }
        ul{
          li{
            border-bottom: 1px solid #E0E0E0;
            line-height:  49px;
          }
        }
      }
      /*contact info*/
      .contact-info{
        ul{
          li{
            margin-bottom:  20px;
            border-bottom: 1px solid #E0E0E0;
            padding-bottom:  20px;
            &:last-child{
              border-bottom: none;
            }
            .contact-info-icons{
              font-family: FontAwesome;
              font-size: 36px;
              font-weight: normal;
              font-style: normal;
              font-stretch: normal;
              line-height: 0.83;
              letter-spacing: normal;
              text-align: left;
              color: #e57632;
              float:  left;
            }
            .contact-info-text{
              padding-left: 50px;
              h4{
                font-family: "Proxima Nova Th";
                font-size: 14px;
                font-weight: bold;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.71;
                letter-spacing: normal;
                text-align: left;
                color: #e57632;
                margin-bottom:  0;
              }
              p{
                margin-bottom:  0;
              }
            }
          }
        }
        iframe{
          width: 100%;
          height: 330px;
          overflow: hidden;
          -webkit-filter: grayscale(100%);
          -moz-filter: grayscale(100%);
          -ms-filter: grayscale(100%);
          -o-filter: grayscale(100%);
          filter: grayscale(100%);
        }

        .contact-info-form{
          background-color: #f4f4f4;
          padding: 20px 0 30px 0;
          margin-top:  40px;
          h3{
            font-family: "Proxima Nova Th";
            font-size: 18px;
            font-weight: bold;
            font-style: italic;
            font-stretch: normal;
            line-height: 1.44;
            letter-spacing: normal;
            text-align: center;
            text-transform:  uppercase;
            color: #22292d;
            margin-bottom: 0;
            padding-bottom: 20px;
            span{
              color: #e57632;
            }
          }
          .form-group{
            margin-bottom: 30px;
          }
          label{
            font-family: "Open Sans";
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 2.14;
            letter-spacing: normal;
            text-align: left;
            color: #22292d;
            text-transform: uppercase;
            sup{
              font-weight: bold;
              color: #e57632;
            }
          }
          form{
            padding: 30px 30px 30px;
          }
          .form-control{
            border-radius: 4px;
            background-color: #ffffff;
            border: solid 1px #e0e0e0;
          }
          input{
            height: 44px;
          }
          textarea{
            height: 140px;
          }
          .vk-btn-send{
            .btn{
              font-family: "Proxima Nova Th";
              font-size: 16px;
              font-weight: normal;
              font-style: italic;
              font-stretch: normal;
              line-height: 42px;
              letter-spacing: normal;
              color: #ffffff;
              border-radius: 4px;
              background-color: #e57632;
              border: 1px solid #e57632;
              padding:  0;
              text-transform:  uppercase;
              width: 140px;
              transition:  all 0.5s;
              &:hover{
                color: #e57632;
                background:  transparent;
              }
            }
          }
        }
      }
    }
  }
}

.uni-single-car-right{
  .uni-single-car-right-price-ma{
    h4{
      font-family: 'proxima_nova_altbold';
      font-size: 44px;
      line-height: 82px;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      background-color: #e57632;
      margin: 0;
      @media screen and (max-width: 1199px) and (min-width: 992px) {
        font-size: 36px;
      }
    }
    p{
      font-size: 24px;
      line-height: 41px;
      letter-spacing: normal;
      text-align: center;
      color: #666666;
      background-color: #f4f4f4;
      span{
        font-size: 14px;
      }
    }
  }

  .uni-trade-make{
    padding:  30px 0;
    button{
      display:  block;
      width: 100%;
      font-family: "Open Sans";
      font-size: 14px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 50px;
      letter-spacing: normal;
      text-align: left;
      color: #22292d;
      border-radius: 4px;
      border: solid 1px #e57632;
      background:  transparent;
      text-transform:  uppercase;
      transition:  all 0.5s;
      padding: 0 20px;
      &.trade{
        margin-bottom: 10px;
      }
      &:hover{
        color: #fff;
        background: #e57632;
        i{
          color: #fff;
        }
      }
      i{
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: left;
        color: #e57632;
        padding-right: 10px;
      }
    }
    label{
      font-family: "Open Sans";
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 2.14;
      letter-spacing: normal;
      text-align: left;
      text-transform:  uppercase;
      color: #22292d;
      sup{
        color: #e57632;
        font-weight: bold;
      }
    }
    .form-control{
      border-radius: 4px;
      background-color: #ffffff;
      border: solid 1px #e0e0e0;
    }
    input{
      height: 44px;
    }
    textarea{
      height: 140px;
    }
    .modal-dialog{
      @media screen and (min-width: 768px) {
        width: 770px !important;
        display:  flex;
        margin: 130px auto;
        position:  relative;
      }
      h2{
        position:  absolute;
        top: -50px;
        font-family: "Proxima Nova Th";
        font-size: 36px;
        font-weight: bold;
        font-style: italic;
        font-stretch: normal;
        line-height: 0.83;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;
        text-transform:  uppercase;
        span{
          color: #e57632;
        }
      }
      .modal-content{
        background-color: #f4f4f4;
        border-radius:  0;
        .uni-shortcode-tabs-default .tabbable-line > .nav-tabs > li.active > a{
          background: transparent;
        }
        .uni-shortcode-tabs-default .tabbable-line > .nav-tabs > li{
          margin-left: 20px;
        }
      }
      .nav-tabs{
        li{
          a{
            font-style: italic;
            padding-top: 20px;
          }
        }
      }
      .tab-content{
        background:  transparent;
        .contact-detail{
          padding:  0 20px;
          button{
            margin-left: 0;
          }
        }
        .uni-upload-img{
          padding:  0 20px;
          h4{
            font-family: "Open Sans";
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 2.14;
            letter-spacing: normal;
            text-align: left;
            text-transform:  uppercase;
            color: #22292d
          }
          ul{
            li{
              display:  inline-block;
              margin-right: 5px;
              width: 115px;
              height: 80px;
              overflow: hidden;
              position: relative;
              &:last-child{
                border-radius: 4px;
                background-color: #e0e0e0;
                font-family: "Open Sans";
                font-size: 30px;
                font-weight: bold;
                font-style: normal;
                font-stretch: normal;
                line-height: 80px;
                letter-spacing: normal;
                text-align: center;
                color: #aeaeae;
                &:hover{
                  cursor: pointer;
                }
              }
              .uni-upload-de-fix{
                position: absolute;
                right: 3px;
                bottom: 3px;
                span{
                  display:  block;
                  float: left;
                  margin-left: 10px;
                  width: 18px;
                  height: 18px;
                  border-radius: 2px;
                  background-color: rgba(0, 0, 0, 0.6);
                  font-family: FontAwesome;
                  font-size: 12px;
                  font-weight: normal;
                  font-style: normal;
                  font-stretch: normal;
                  line-height: 2.5;
                  letter-spacing: normal;
                  text-align: center;
                  color: #ffffff;
                  transition:  all 0.5s;
                  &:hover{
                    color: #e57632;
                    cursor: pointer;
                  }
                }
                .fix{
                  display: flex;
                  align-items: center;
                  justify-content: center;

                }
                .delete{
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
              }
            }
          }
        }
        .uni-paste-a-link{
          padding: 0 20px;
          margin-bottom: 30px;
          margin-top: 20px;
        }
        button {
          display: block;
          width: auto;
          font-family: "Open Sans";
          font-size: 14px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: 42px;
          letter-spacing: normal;
          text-align: left;
          color: #fff;
          border-radius: 4px;
          border: solid 1px #e57632;
          background: #e57632;
          text-transform: uppercase;
          transition: all 0.5s;
          padding: 0 20px;
          margin:  0 20px;
          &:hover{
            background:  #fff;
            color: #e57632;
          }
        }
      }
    }
  }

  .uni-make-an-offer-price{
    .modal-title{
      font-family: "Proxima Nova Th";
      font-size: 24px;
      font-weight: bold;
      font-style: italic;
      font-stretch: normal;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: left;
      text-transform:  uppercase;
      color: #22292d;
    }
    .modal-body{
      img{
        width: 100%;
        height: auto;
      }
    }
    .modal-footer{
      text-align:  left;
      .vk-btn-send{
        margin-top: 20px;
      }
      button{
        width: auto;
        line-height: 42px;
        background: #e57632;
        color: #fff;
        transition:  all 0.5s;
        &:hover{
          color: #e57632;
          background:  transparent;
        }
      }
    }
  }

  .uni-vehicles-features{
    h4{
      font-family: 'Proxima Nova Th';
      font-size: 18px;
      font-weight: bold;
      font-style: italic;
      font-stretch: normal;
      line-height: 60px;
      letter-spacing: normal;
      text-align: left;
      color: #ffffff;
      background-color: #22292d;
      padding:  0 20px;
      text-transform:  uppercase;
      margin:  0;
      @media screen and (max-width: 1199px) and (min-width: 992px) {
        font-size: 14px;
      }
      span{
        color: #e57632;
      }
    }
    .uni-vehicles-features-info{
      background-color: #f4f4f4;

      padding: 0 20px;
    }
    table{
      tr{
        padding:  0 20px;
        td{
          padding-left: 0;
          padding-right: 0;
          &:first-child{
            font-family: "Open Sans";
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 3.57;
            letter-spacing: normal;
            text-align: left;
            color: #666666;
            @media screen and (max-width: 1199px) and (min-width: 992px) {
              font-size: 12px;
            }
          }
          &:last-child{
            font-family: "Open Sans";
            font-size: 14px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: 3.57;
            letter-spacing: normal;
            text-align: left;
            color: #22292d;
            @media screen and (max-width: 1199px) and (min-width: 992px) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  .uni-payment-calculator{
    background-color: #f4f4f4;
    padding-bottom:  20px;
    h4{
      font-family: 'Proxima Nova Th';
      font-size: 18px;
      font-weight: bold;
      font-style: italic;
      font-stretch: normal;
      line-height: 60px;
      letter-spacing: normal;
      text-align: left;
      color: #ffffff;
      background-color: #22292d;
      padding:  0 20px;
      text-transform:  uppercase;
      margin:  0;
      @media screen and (max-width: 1199px) and (min-width: 992px) {
        font-size: 14px;
      }
      span{
        color: #e57632;
      }
    }
    form{
      padding: 20px;
      label{
        font-family: "Open Sans";
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 2.14;
        letter-spacing: normal;
        text-align: left;
        color: #666666;
        span{
          color: #e57632;
        }
      }
      input{
        height: 44px;
        border-radius: 4px;
        background-color: #ffffff;
        border: solid 1px #e0e0e0;
        font-family: "Open Sans";
        font-size: 14px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 2.14;
        letter-spacing: normal;
        text-align: left;
        color: #22292d;
        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          font-family: "Open Sans";
          font-size: 14px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: 2.14;
          letter-spacing: normal;
          text-align: left;
          color: #22292d;
        }
        &::-moz-placeholder { /* Firefox 19+ */
          font-family: "Open Sans";
          font-size: 14px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: 2.14;
          letter-spacing: normal;
          text-align: left;
          color: #22292d;
        }
        &:-ms-input-placeholder { /* IE 10+ */
          font-family: "Open Sans";
          font-size: 14px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: 2.14;
          letter-spacing: normal;
          text-align: left;
          color: #22292d;
        }
        &:-moz-placeholder { /* Firefox 18- */
          font-family: "Open Sans";
          font-size: 14px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: 2.14;
          letter-spacing: normal;
          text-align: left;
          color: #22292d;
        }
      }
      .controls{
        button{
          display:  block;
          width: 100%;
          font-family: 'Proxima Nova Th';
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 44px;
          padding: 0;
          letter-spacing: normal;
          text-align: center;
          color: #ffffff;
          border-radius: 4px;
          background-color: #e57632;
          border: 1px solid #e57632;
          transition:  all 0.5s;
          text-transform:  uppercase;
          &:hover{
            background:  transparent;
            color: #e57632;
          }
        }
      }
    }
    ul{
      background-color: #e0e0e0;
      padding: 5px 20px 20px 20px;
      margin: 0 20px;
      li{
        padding-top: 10px;
        span{
          font-family: "Open Sans";
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.86;
          letter-spacing: normal;
          text-align: left;
          color: #22292d;
        }
        p{
          font-weight: bold;
          font-size: 16px;
          color: #e57632;
        }
      }
    }
  }
}