/* This stylesheet generated by Transfonter (https://transfonter.org) on December 22, 2017 10:20 AM */

@font-face {
    font-family: 'Proxima Nova Lt';
    src: url('./fonts/ProximaNova-Light.eot');
    src: url('./fonts/ProximaNova-Light.eot?#iefix') format('embedded-opentype'),
        url('./fonts/ProximaNova-Light.woff2') format('woff2'),
        url('./fonts/ProximaNova-Light.woff') format('woff'),
        url('./fonts/ProximaNova-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Cn Lt';
    src: url('./fonts/ProximaNovaCond-SemiboldIt.eot');
    src: url('./fonts/ProximaNovaCond-SemiboldIt.eot?#iefix') format('embedded-opentype'),
        url('./fonts/ProximaNovaCond-SemiboldIt.woff2') format('woff2'),
        url('./fonts/ProximaNovaCond-SemiboldIt.woff') format('woff'),
        url('./fonts/ProximaNovaCond-SemiboldIt.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Proxima Nova Bl';
    src: url('./fonts/ProximaNova-Black.eot');
    src: url('./fonts/ProximaNova-Black.eot?#iefix') format('embedded-opentype'),
        url('./fonts/ProximaNova-Black.woff2') format('woff2'),
        url('./fonts/ProximaNova-Black.woff') format('woff'),
        url('./fonts/ProximaNova-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Th';
    src: url('./fonts/ProximaNova-Extrabld.eot');
    src: url('./fonts/ProximaNova-Extrabld.eot?#iefix') format('embedded-opentype'),
        url('./fonts/ProximaNova-Extrabld.woff2') format('woff2'),
        url('./fonts/ProximaNova-Extrabld.woff') format('woff'),
        url('./fonts/ProximaNova-Extrabld.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Cn Bl';
    src: url('./fonts/ProximaNovaCond-Black.eot');
    src: url('./fonts/ProximaNovaCond-Black.eot?#iefix') format('embedded-opentype'),
        url('./fonts/ProximaNovaCond-Black.woff2') format('woff2'),
        url('./fonts/ProximaNovaCond-Black.woff') format('woff'),
        url('./fonts/ProximaNovaCond-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Rg';
    src: url('./fonts/ProximaNova-BoldIt.eot');
    src: url('./fonts/ProximaNova-BoldIt.eot?#iefix') format('embedded-opentype'),
        url('./fonts/ProximaNova-BoldIt.woff2') format('woff2'),
        url('./fonts/ProximaNova-BoldIt.woff') format('woff'),
        url('./fonts/ProximaNova-BoldIt.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Proxima Nova Cn Lt';
    src: url('./fonts/ProximaNovaCond-LightIt.eot');
    src: url('./fonts/ProximaNovaCond-LightIt.eot?#iefix') format('embedded-opentype'),
        url('./fonts/ProximaNovaCond-LightIt.woff2') format('woff2'),
        url('./fonts/ProximaNovaCond-LightIt.woff') format('woff'),
        url('./fonts/ProximaNovaCond-LightIt.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Proxima Nova Lt';
    src: url('./fonts/ProximaNova-LightIt.eot');
    src: url('./fonts/ProximaNova-LightIt.eot?#iefix') format('embedded-opentype'),
        url('./fonts/ProximaNova-LightIt.woff2') format('woff2'),
        url('./fonts/ProximaNova-LightIt.woff') format('woff'),
        url('./fonts/ProximaNova-LightIt.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Proxima Nova Cn Lt';
    src: url('./fonts/ProximaNovaCond-Light.eot');
    src: url('./fonts/ProximaNovaCond-Light.eot?#iefix') format('embedded-opentype'),
        url('./fonts/ProximaNovaCond-Light.woff2') format('woff2'),
        url('./fonts/ProximaNovaCond-Light.woff') format('woff'),
        url('./fonts/ProximaNovaCond-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Rg';
    src: url('./fonts/ProximaNova-Bold.eot');
    src: url('./fonts/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/ProximaNova-Bold.woff2') format('woff2'),
        url('./fonts/ProximaNova-Bold.woff') format('woff'),
        url('./fonts/ProximaNova-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Cn Lt';
    src: url('./fonts/ProximaNovaCond-Semibold.eot');
    src: url('./fonts/ProximaNovaCond-Semibold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/ProximaNovaCond-Semibold.woff2') format('woff2'),
        url('./fonts/ProximaNovaCond-Semibold.woff') format('woff'),
        url('./fonts/ProximaNovaCond-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Cn Rg';
    src: url('./fonts/ProximaNovaCond-Regular.eot');
    src: url('./fonts/ProximaNovaCond-Regular.eot?#iefix') format('embedded-opentype'),
        url('./fonts/ProximaNovaCond-Regular.woff2') format('woff2'),
        url('./fonts/ProximaNovaCond-Regular.woff') format('woff'),
        url('./fonts/ProximaNovaCond-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Th';
    src: url('./fonts/ProximaNova-Extrabld_1.eot');
    src: url('./fonts/ProximaNova-Extrabld_1.eot?#iefix') format('embedded-opentype'),
        url('./fonts/ProximaNova-Extrabld_1.woff2') format('woff2'),
        url('./fonts/ProximaNova-Extrabld_1.woff') format('woff'),
        url('./fonts/ProximaNova-Extrabld_1.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Lt';
    src: url('./fonts/ProximaNova-Semibold.eot');
    src: url('./fonts/ProximaNova-Semibold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/ProximaNova-Semibold.woff2') format('woff2'),
        url('./fonts/ProximaNova-Semibold.woff') format('woff'),
        url('./fonts/ProximaNova-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Cn Rg';
    src: url('./fonts/ProximaNovaCond-RegularIt.eot');
    src: url('./fonts/ProximaNovaCond-RegularIt.eot?#iefix') format('embedded-opentype'),
        url('./fonts/ProximaNovaCond-RegularIt.woff2') format('woff2'),
        url('./fonts/ProximaNovaCond-RegularIt.woff') format('woff'),
        url('./fonts/ProximaNovaCond-RegularIt.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}
