/*ABOUT US*/
.uni-about-us{
  overflow: hidden;  
}
.uni-about-us-body{
  padding-top: 80px;
}
.title-default{
  font-family: "Proxima Nova Th";
  font-size: 36px;
  font-weight: bold;
  font-style: italic;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  color: #22292d;
  text-transform:  uppercase;
  padding-bottom: 10px;
  margin-bottom:  0;
  @media screen and (max-width: 767px){
    font-size: 30px;
  }
  span{
    color: #e57632;
  }
}

.uni-underline{
  background: url("../../images/about/underline.png") no-repeat;
  background-position: center center;
  height: 16px;
}
.uni-about-us-welcome-text{
  padding-top: 60px;
  p{
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.86;
    letter-spacing: normal;
    text-align: center;
    color: #666666;
  }
  h4{
    font-family: "Proxima Nova Th";
    font-size: 14px;
    font-weight: bold;
    font-style: italic;
    font-stretch: normal;
    line-height: 1.86;
    letter-spacing: normal;
    text-align: center;
    color: #22292d;
    text-transform: uppercase;
    margin-top: 60px;
    span{
      color: #e57632;
    }
  }
}

.uni-about-us-welcome-img{
  margin-top: 60px;
  img{
    width: 100%;
    height:  auto;
  }
}

/*OUR TEAM*/
.uni-about-our-team{
  background-color: #f4f4f4;
  padding: 100px 0;
  .uni-our-team-body{
    padding-top: 60px;
  }
}

/*WHY CHOOSE SMARTCAR*/
.uni-about-why-choose-smartcar{
  padding-top:  80px;
  .title-default{
    color: #fff;
    span{
      color: #e57632;
    }
  }
}

/*CUSTOMERS SAY*/
.uni-about-customer-say{
  padding:  95px 0 80px 0;
  .title-default{
    text-align:  left;
    color: #e57632;
    span{
      color: #22292d;
    }
  }
  .uni-underline-left{
    background: url("../../images/about/underline1.png") no-repeat;
    height: 16px;
  }

  .uni-about-customer-say-left{
    position:  relative;
    .uni-about-customer-say-left-body{
      padding-top:  60px;
    }
    .owl-theme .owl-nav{
       position:  absolute;
       top: -145px;
       right: 0;
      @media screen and (max-width: 767px) {
        display: none;
      }
     }
    .owl-theme .owl-dots{
      position:  absolute;
      top: -50px;
      right: 0;
    }
    .owl-theme .owl-dots .owl-dot span{
      width: 25px;
      height: 6px;
      background-color: #e0e0e0;
      border-radius: 0;
      margin: 5px 3px;
      transform: skew(-40deg);
    }
    .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{
      background: #e57632;
    }
    .owl-theme .owl-nav [class*=owl-]{
      width: 44px;
      height: 44px;
      border-radius: 4px;
      border: solid 1px #e57632;
      background: #fff;
      transition:  all 0.5s;
      &:hover{
        background:  #e57632;
        &:before{
          color:  #fff;
        }
      }
    }
    .owl-theme .owl-nav .owl-prev:before{
      content: '\f053';
      font-family:  FontAwesome;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 35px;
      letter-spacing: normal;
      color: #aeaeae;
    }
    .owl-theme .owl-nav .owl-next:before{
      content: '\f054';
      font-family:  FontAwesome;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 35px;
      letter-spacing: normal;
      text-align: left;
      color: #aeaeae;
    }
  }

  .uni-about-customer-say-right{
    ul{
      li{
        display:  flex;
        align-items:  center;
        justify-content:  center;
        height: 110px;
        background-color: #e0e0e0;
        float: left;
        width: calc(100% / 3);
        border: 5px solid #fff;
        border-radius: 10px;
        @media screen and (max-width: 767px) {
          width: calc(100%/2);
        }
      }
    }
  }
}

.uni-about-map{
  position:  relative;
  iframe{
    width: 100%;
    height: 700px;
    overflow: hidden;
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
  }
  .uni-about-contact-info{
    position:  absolute;
    width:  100%;
    @media screen and (min-width: 992px) {
    z-index:  999;
    }
    margin-top: -110px;
    @media screen and (max-width: 991px) {
      position: relative;
      margin-top: 5px;
      margin-bottom: 10px;
    }
    @media screen and (max-width: 767px) {
      padding-left: 10px;
      padding-right: 10px;
    }
    .uni-about-contact-info-body{
      background: #e57632;
      display:  flex;
      border-radius: 4px;
      @media screen and (max-width: 991px) {
        display:  block;
        padding: 30px 5px;
      }
      .item{
        padding:  30px  0;
        @media screen and (max-width: 991px) {
          border-width: 160px;
          border-bottom: 1px solid #22292d;
        }
        ul{
          padding:  30px  0;
          border-right: 1px solid #22292d;
          @media screen and (max-width: 991px) {
            border-right: none;
          }
          li{
            &.contact-icon{
              font-family: FontAwesome;
              font-size: 36px;
              font-weight: normal;
              font-style: normal;
              font-stretch: normal;
              line-height: 0.83;
              letter-spacing: normal;
              text-align: center;
              color: #22292d;
            }
            &.contact-text{
              h4{
                font-family: "Proxima Nova Th";
                font-size: 18px;
                font-weight: bold;
                font-style: normal;
                font-stretch: normal;
                line-height: 2;
                letter-spacing: normal;
                text-align: center;
                color: #22292d;
                text-transform: uppercase;
                margin-bottom:  0;
                margin-top: 10px;
              }
              p{
                font-family: "proxima_nova_altbold";
                font-size: 18px;
                font-weight: bold;
                font-style: normal;
                font-stretch: normal;
                line-height: 2;
                letter-spacing: normal;
                text-align: center;
                text-transform: uppercase;
                color: #ebeff0;
              }
            }
          }
        }
      }
      > .col-md-4{
        &:last-child{
          .item{
            ul{
              border-right: none;
            }
            @media screen and (max-width: 991px){
                border-bottom: none;
            }
          }
        }
      }
    }
  }
}