/*SEARCH INVENTORY*/
.uni-search_inventory-body{
  padding:  80px 0;
  background: #f4f4f4;
  .show-result-title{
    font-family: "Proxima Nova Th";
    font-size: 36px;
    font-weight: bold;
    font-style: italic;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #22292d;
    text-transform:  uppercase;
    span{
      color: #e57632;
    }
  }

  .uni-shortcode-tabs-default .tabbable-line > .tab-content{
    background:  transparent;
    border-top: 2px solid #E0E0E0;
    padding: 30px 0;margin-top: -2px;
  }
  .nav-tabs>li.active>a{
    background:  transparent;
  }
  .uni-shortcode-tabs-default .tabbable-line > .nav-tabs > li:first-child > a{
  padding-left:0;
  }
  .uni-search-inventory{
    .item{
      padding-bottom: 40px;
      .item-img{
        position:  relative;
        overflow:  hidden;
        &:hover{
          img{
            transform: scale(1.1);
          }
        }
        img{
          width: 100%;
          height: auto;
          transition:  all 0.5s;
        }
        .item-img-caption{
          position:  absolute;
          right: 0;
          bottom: 20px;
          p{
            font-family: "proxima_nova_altbold";
            font-size: 20px;
            font-weight: bold;
            font-style: italic;
            font-stretch: normal;
            line-height: 40px;
            letter-spacing: normal;
            text-align: center;
            color: #ffffff;
            background-color: #e57632;
            padding:  0 20px;
          }
        }
      }
      .item-info{
        padding-top: 20px;
        h3{
          a{
            display:  block;
            font-family: "Proxima Nova Th";
            font-size: 18px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: 0.94;
            letter-spacing: normal;
            text-align: left;
            color: #333333;
            text-transform:  uppercase;
            transition:  all 0.5s;
            &:hover{
              color: #e57632;
            }
          }
        }
        ul{
          display:  inline-block;
          li{
            width: 50%;
            float:  left;
            margin-top:  10px;
            .left-item{              
              .left-item-info{
                padding-left: 30px;
                span{
                  font-family: "Open Sans";
                  font-size: 14px;
                  font-weight: normal;
                  font-style: normal;
                  font-stretch: normal;
                  line-height: 1.21;
                  letter-spacing: normal;
                  text-align: left;
                  color: #666666;
                }
              }
            }
          }
        }
      }
    }
  }
}