.uni-shortcode-typography-body{

}
.uni-typography-heading-default{
  padding: 80px 0 40px 0;
  .item-typography{
    padding-bottom: 40px;
  }
  h1{
    font-family: 'Proxima Nova Th';
    font-size: 48px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.63;
    letter-spacing: normal;
    text-align: left;
    color: #22292d;
    margin: 0;
  }
  h2{
    font-family: 'Proxima Nova Th';
    font-size: 36px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.83;
    letter-spacing: normal;
    text-align: left;
    color: #22292d;
    margin: 0;
  }
  h3{
    font-family: 'Proxima Nova Th';
    font-size: 30px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #22292d;
    margin: 0;
  }
  h4{
    font-family: 'Proxima Nova Th';
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #22292d;
    margin: 0;
  }
  h5{
    font-family: 'Proxima Nova Th';
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: left;
    color: #22292d;
    margin: 0;
  }
  h6{
    font-family: 'Proxima Nova Th';
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.14;
    letter-spacing: normal;
    text-align: left;
    color: #22292d;
    margin: 0;
  }
  p{
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
    color: #666666;
    padding-top: 30px;
  }
}

/*TEXT ALIGHT*/
.uni-typography-text-alight-default{
  padding-top: 80px;
  padding-bottom: 20px;
  h3{
    font-family: 'Proxima Nova Th';
    font-size: 30px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #22292d;
    margin: 0;
  }
  p{
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #666666;
    padding-bottom: 60px;
    padding-top: 30px;
  }
}

.text-align-left{
  text-align: left;
}
.text-align-right{
  text-align: right;
}
.text-align-center{
  text-align: center;
}

/*BLOCK QUOUTE*/
.uni-typography-block-quote{
  padding: 80px 0;
}
.uni-block-quote-default{
  h3{
    font-family: 'Proxima Nova Th';
    font-size: 30px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #22292d;
    margin: 0;
    padding-bottom: 40px;
  }
  blockquote{
    padding: 20px;
    border-left: none;
    padding-left: 80px;
    background-color: #f4f4f4;
    box-shadow: inset 5px 0 0 0 #e57632;
  }
  p{
    position: relative;
    font-family: "Open Sans";
    font-size: 24px;
    font-weight: normal;
    font-style: italic;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #22292d;
    &:before {
      font-family: Georgia, serif;
      content: "“";
      position: absolute;
      top: -30px;
      left: -70px;
      font-size: 5em;
      color: #e57632;
      font-weight: normal;
    }
  }
}