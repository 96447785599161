.uni-color-ffc000{
  background: #e57632;
}
.uni-color-cc2981{
  background: #cc2981;
}
.uni-color-a029cc{
  background: #a029cc;
}
.uni-color-4429cc{
  background: #4429cc;
}
.uni-color-2962cc{
  background: #2962cc;
}
.uni-color-29afcc{
  background: #29afcc;
}
.uni-color-29ccab{
  background: #29ccab;
}
.uni-color-29cc7a{
  background: #29cc7a;
}
.uni-color-7dcc29{
  background: #7dcc29;
}
.uni-color-ccbb29{
  background: #ccbb29;
}
.uni-color-cc7229{
  background: #cc7229;
}
.uni-color-7295d5{
  background: #7295d5;
}
.uni-color-223c77{
  background: #223c77;
}
.uni-color-6d9896{
  background: #6d9896;
}
.uni-color-945f5f{
  background: #945f5f;
}
.uni-color-ad7292{
  background: #ad7292;
}
.uni-color-87a16b{
  background: #87a16b;
}
.uni-color-fdc689{
  background: #fdc689;
}
.uni-color-005b7f{
  background: #005b7f;
}
.uni-color-f68e56{
  background: #f68e56;
}
.uni-color-6dcff6{
  background: #6dcff6;
}
.uni-color-f26d7d{
  background: #f26d7d;
}
.uni-color-9e005d{
  background: #9e005d;
}
.uni-color-a186be{
  background: #a186be;
}