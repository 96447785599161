/*404 PAGE*/
.uni-404page-body{
  padding: 80px 0;
  text-align: center;
  i{
    font-family: FontAwesome;
    font-size: 100px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #e57632;
  }
  h2{
    font-family: "Proxima Nova Th";
    font-size: 48px;
    font-weight: bold;
    font-style: italic;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: center;
    color: #e57632;
    text-transform:  uppercase;
  }
  p{
    font-family: "Open Sans";
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #666666;
  }
  a{
    display: inline-block;
    font-family: "Proxima Nova Th";
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    line-height: 50px;
    text-align: center;
    border-radius: 4px;
    text-transform:  uppercase;
    color: #ffffff;
    padding:  0 30px;
    i{
      font-size: 14px;
      color: #fff;
    }
    &.goback{
      background-color: #22292d;
      border: 1px solid #22292d;
      transition:  all 0.5s;
      &:hover{
        color:#22292d;
        background: transparent;
        i{
          color: #22292d;
        }
      }
    }
    &.homepage{
      background-color: #e57632;
      border: 1px solid #e57632;
      transition:  all 0.5s;
      &:hover{
        color:#e57632;
        background: transparent;
        i{
          color: #e57632;
        }
      }
    }
  }
}