.uni-shortcode-accordion{
  .uni-shortcode-accordion-body{
    padding: 80px 0;
  }
}
.accordion-default{
  border: 1px solid #E0E0E0;
  .accordion-content {
    display: none;
    margin: 0 20px;
    border-top: 1px solid #E0E0E0;
    padding: 20px 0;
    p{
      font-family: "Open Sans";
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.86;
      letter-spacing: normal;
      text-align: left;
      color: #666666;
    }
  }
  .accordion-toggle {
    position: relative;
    cursor: pointer;
    margin: 0;
    padding: 20px;
    padding-left: 65px;
  }
  .accordion-toggle.active:after{
    content:"\f068";
    position: absolute;
    left: 20px;
    top: 15px;
    font-size: 14px;
    font-family: FontAwesome;
    color: #fff;
    font-weight: normal;
    width: 30px;
    height: 30px;
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e57632;
    border-radius: 3px;
  }
  .accordion-toggle:before{
    content: "\f067";
    position: absolute;
    left: 20px;
    top: 15px;
    font-size: 14px;
    font-weight: normal;
    font-family: FontAwesome;
    color: #fff;
    width: 30px;
    height: 30px;
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e57632;
    border-radius: 3px;
  }
}