@import "header/header";

@import "footer/footer";

/*Home page*/
@import "homepage/car_dealer_1";

// @import "homepage/car_dealer_2";

// @import "homepage/car_rental";

// @import "homepage/car_services";

/*Page*/
@import "page/about";

@import "page/sell_a_car";

@import "page/sell_a_car_2";

@import "page/compare";

@import "page/contact";

@import "page/faq";

@import "page/404pages";

@import "page/comming_soon";

@import "page/search_inventory";

@import "page/search_post";

@import "page/search_product";

/*SERVICES*/
@import "our-services/our-services";

/*INVENTORY*/
@import "inventory/inventory_list";

@import "inventory/inventory_grid";

@import "inventory/single_car";

@import "inventory/single_car_2";

@import "inventory/single_car_3";

@import "inventory/single_car_4";

@import "inventory/trade_in_form";

@import "inventory/make_an_offer_price";

/*BLOG*/
@import "blog/blog_list";

@import "blog/blog_grid";

@import "blog/single_post";

/*SHOP*/
@import "shop/shop";

@import "shop/checkout";

@import "shop/cart";

@import "shop/single_product";

/*ELEMENT*/
@import "shortcode/shortcode";