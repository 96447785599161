/*SINGLE POST*/
.uni-single-post-body{
  padding:  80px 0;
  .uni-entry-header{
    .uni-entry-title{
      display:  block;
      font-family: 'Proxima Nova Th';
      font-size: 30px;
      font-weight: bold;
      font-style: italic;
      font-stretch: normal;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: left;
      color: #22292d;
      text-transform: uppercase;
    }
  }

  .uni-entry-meta{
    display: inline-block;
    padding: 30px 0;
    span{
      display: block;
      float: left;
      font-family: "Open Sans";
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      text-align: center;
      color: #343c40;
      border: 1px solid #e57632;
      border-radius: 5px;
      margin-right: 20px;

      @media screen and (max-width: 1199px) {
        margin-bottom: 20px;
      }
      i{
        color: #e57632;
        padding-right: 5px;
        transition:  all 0.5s;
      }
      a{
        display: block;
        padding: 0 20px;
        line-height: 40px;
        color: #343c40;
        transition:  all 0.5s;
        &:hover{
          color: #fff;
          background: #e57632;
          i{
            color: #fff;
          }
        }
      }
    }
  }
  .uni-entry-description{
    padding: 40px 0;
    p{
      font-family: "Open Sans";
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.86;
      letter-spacing: normal;
      text-align: left;
      color: #666666;
    }
  }
  .uni-block-quote-default{
    p{
      font-family: "Open Sans";
      font-size: 24px;
      font-weight: normal;
      font-style: italic;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: #22292d;
    }
  }

  .uni-single-post-services{
    padding-top: 20px;
    padding-bottom: 40px;
    .uni-single-post-services-img{
      background: url("../../images/single-post/img11.jpg") no-repeat;
      background-position: center center;
      min-height: 440px;
      background-size: cover;
    }
    .uni-single-post-services-info{
      @media screen and (max-width: 991px) {
        margin-top: 20px;
      }
      h4{
        font-family: 'Proxima Nova Th';
        font-size: 18px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        text-align: left;
        text-transform: uppercase;
        color: #22292d;
      }
      p{
        font-family: "Open Sans";
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.86;
        letter-spacing: normal;
        text-align: left;
        color: #666666;
      }
      ul{
        li{
          font-family: "Open Sans";
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 2.57;
          letter-spacing: normal;
          text-align: left;
          color: #666666;
          i{
            color: #e57632;
          }
        }
      }
    }
  }
  .uni-entry-tag-share{
    padding-top: 20px;
    padding-bottom: 60px;
    ul{
      li{
        display:  inline-block;
        margin-right: 10px;
        margin-bottom: 10px;
        &:first-child{
          font-family: "Open Sans";
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 40px;
          letter-spacing: normal;
          text-align: center;
          border-radius: 4px;
          color: #ffffff;
          background: #e57632;
          padding: 0 20px;
        }
        a{
          display:  block;
          width: 40px;
          font-family: FontAwesome;
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 40px;
          letter-spacing: normal;
          text-align: center;
          border-radius: 4px;
          border: solid 1px #e0e0e0;
          color: #aeaeae;
          &:hover{
            color: #e57632;
            border-color: #e57632;
          }
        }
      }
    }
  }

  .uni-author{
    .uni-author-title{
      font-family: 'Proxima Nova Th';
      font-size: 14px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 40px;
      letter-spacing: normal;
      text-align: left;
      background-color: #22292d;
      color: rgba(255, 255, 255, 0.5);
      padding-left: 20px;
    }
    .uni-author-info{
      .item-img{
        width: 150px;
        height: 150px;
        margin-bottom: 15px;
        @media screen and (min-width:481px){
          float: left;
        }
        img{
          width: 100%;
          height: auto;
        }
      }
      .item-content{
        @media screen and (min-width:481px){
          padding-left: 170px;
        }        
        min-height: 150px;
        .author-name{
          display: block;
          font-family: 'Proxima Nova Th';
          font-size: 18px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.33;
          letter-spacing: normal;
          text-align: left;
          color: #22292d;
          padding-bottom: 10px;
        }
        p{
          font-family: "Open Sans";
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.71;
          letter-spacing: normal;
          text-align: left;
          color: #666666;
        }
        a{
          font-family: "Open Sans";
          font-size: 14px;
          font-weight: normal;
          font-style: italic;
          font-stretch: normal;
          line-height: 1.71;
          letter-spacing: normal;
          text-align: left;
          color: #e57632;
        }
        ul{
          padding-top: 10px;
          li{
            display:  inline-block;
            padding-right: 10px;
            a{
              font-family: FontAwesome;
              font-size: 14px;
              font-weight: normal;
              font-style: normal;
              font-stretch: normal;
              line-height: 1.71;
              letter-spacing: normal;
              text-align: left;
              color: #aeaeae;
              transition:  all 0.5s;
              &:hover{
                color: #e57632;
              }
            }
          }
        }
      }
    }
  }
}

.comments-area{
  padding-top: 60px;
  .list-comments{
    h3{
      font-family: 'Proxima Nova Th';
      font-size: 18px;
      font-weight: bold;
      line-height: 1.67;
      text-align: left;
      color: #040400;
      text-transform: uppercase;
      i{
        color: #e57632;
        padding-right: 10px;
      }
    }
    .comment-list{
      padding: 30px 0 50px 0;
      li{
        .comments-img{
          float:  left;
        }
        &.depth-1{
          img{

          }
          .content-comment{
            padding-left: 100px;
            .author{
              span{
                font-family: 'Open Sans';
                font-size: 14px;
                font-weight: bold;
                line-height: 1.71;
                text-align: left;
                color: #aeaeae;
                &.author-name{
                  color: #404040;
                }
              }
            }
            .message{
              padding: 10px 0;
              p{
                font-family: 'Open Sans';
                font-size: 14px;
                line-height: 1.86;
                text-align: left;
                color: #666666;
                margin: 0;
              }
            }
            .reply{
              a{
                font-family: "Open Sans";
                font-size: 14px;
                font-weight: normal;
                font-style: italic;
                font-stretch: normal;
                line-height: 1.71;
                letter-spacing: normal;
                text-align: left;
                color: #e57632;
                transition: all 0.3s;
                &:hover{
                  color: #333333;
                }
              }
              .comment-extra-info{
                font-family: "Open Sans";
                font-size: 14px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.71;
                letter-spacing: normal;
                text-align: left;
                color: #aeaeae;
                &:after{
                  content: '.';
                  padding: 0 10px;
                }
              }
            }
          }
        }
      }
    }
    .children{
      padding-left: 100px;
      padding-top: 30px;
      @media screen and (max-width:480px){
        padding-left: 40px;
      }
      .content-comment {
          @media screen and (max-width:480px){
            padding-left: 80px !important;
          }
      }
    }
  }

  .form-comment{
    h3{
      font-family: 'Proxima Nova Th';
      font-size: 18px;
      font-weight: bold;
      line-height: 1.67;
      text-align: left;
      color: #333333;
      text-transform: uppercase;
      i{
        color: #e57632;
        padding-right: 10px;
      }
    }
    .form-group{
      margin-bottom:  0;
    }
    .uni-message-cause-form{
      padding-top: 30px;
      input{
        height: 50px;
      }
      textarea{
        height: 160px;
      }
      .form-control{
        margin-bottom: 10px;
        background: #f4f4f4;
        border-radius: 4px;
        border-color: transparent;
        box-shadow:  none;
      }
      .btn{
        background: #e57632;
        border: 1px solid #e57632;
        border-radius: 3px;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        color: #fff;
        height: 40px;
        text-transform:  uppercase;
        padding: 6px 23px;
        transition: all 0.5s;
        &:hover{
          color: #e57632;
          background: #fff;
          border: 1px solid #e57632;
          border-radius: 3px;
          transition: all 0.5s;
        }
      }
      .vk-btn-send{
        text-align: left;
        margin-top: 30px;
      }
    }
  }
}