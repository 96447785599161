/*COLUMNS*/
.uni-shortcode-columns{
  .uni-shortcode-columns-body{
    padding-top: 100px;
    padding-bottom: 40px;
  }
  p{
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
    color: #666666;
  }
  h3{
    font-family: 'Proxima Nova Th';
    font-size: 30px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #22292d;
    margin: 0;
    padding-bottom: 40px;
    padding-bottom: 30px;
  }
  .item-col{
    padding-bottom: 60px;
  }
}