ul{
  padding: 0;
  margin: 0;
  li{
    list-style-type:  none;
  }
}
.uni-uppercase{
  text-transform: uppercase;
}
a{
  &:hover{
    text-decoration: none;
  }
}
.text-align-center{
  text-align: center;
}
.uni-banner-background-1{
  background: url("./images/banner/background.jpg"), no-repeat;
}
.uni-background-2{
  background: url("./images/ion-boxes/background.jpg"), no-repeat;
}
.uni-background-3{
  background: url("./images/counter/background1.jpg"), no-repeat;
}
.uni-background-4{
  background: url("./images/services/background.jpg"), no-repeat;
}
.uni-background-5{
  background: url("./images/car-deal/slider/background11.jpg"), no-repeat;
}
.uni-background-6{
  background: url("./images/car-deal/slider/background111.jpg"), no-repeat;
}
.uni-background-7{
  background: url("./images/car-deal/slider/background112.jpg"), no-repeat;
}
.uni-background-8{
  background: url("./images/car-deal/sell-a-car/backgroundcopy.jpg"), no-repeat;
}
.uni-background-9{
  background: url("./images/car-deal/feature-cars/img13.jpg"), no-repeat;
}
.uni-background-10{
  background: url("./images/car-deal/feature-cars/img14.jpg"), no-repeat;
}
.uni-background-11{
  background: url("./images/car-deal/feature-cars/img15.jpg"), no-repeat;
}
.uni-background-12{
  background: url("./images/car-deal/feature-cars/img16.jpg"), no-repeat;
}
.uni-background-13{
  background: url("./images/car-deal/why-choose-us/background.jpg"), no-repeat;
}
.uni-background-14{
  background: url("./images/car-dealer-2/ourservices/background.jpg"), no-repeat;
}
.uni-background-15{
  background: url("./images/car-services/background1.jpg"), no-repeat;
}
.uni-background-16{
  background: url("./images/car-services/ourservices/background.jpg"), no-repeat;
}
.uni-background-17{
  background: url("./images/car-rental/background.jpg"), no-repeat;
}
.uni-background-18{
  background: url("./images/car-rental/background1.jpg"), no-repeat;
}
.clear-padding{
  padding:  0;
}