/*BUTTONS*/
.uni-shortcode-buttons{
  .uni-shortcode-button-title{
    padding-bottom: 30px;
  }
  h2{
    font-family: 'Proxima Nova Th';
    font-size: 30px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #22292d;
    text-transform: uppercase;
  }
  .uni-button-medium{
    margin: 45px 0;
  }
  .uni-shortcode-buttons-body{
    padding: 100px 0;
    a{
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Proxima Nova Th';
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      color: #ffffff;
      border-radius: 3px;
      transition:  all 0.5s;
    }
  }
}

.vk-btn-default{
  margin-bottom: 30px;
  &:hover{
    background-color: transparent;
    color: #e57632;
    border-color: #e57632;
  }
  &:focus{
    background-color: transparent;
    color: #e57632;
    border-color: #e57632;
  }
}
.vk-btn-xs{
  min-height: 40px;
  font-size: 12px;
  padding: 8px 20px;
}
.vk-btn-l{
  min-height: 60px !important;
  padding: 13px 20px;
}
.vk-btn-m{
  min-height: 50px;
  transition: all 0.5s;
}
.bg-ffc000{
  background: #e57632;
  &:hover{
    border: 1px solid #e57632;
    color: #e57632 !important;
  }
  &:focus{
    border: 2px solid #e57632;
    color: #e57632 !important;
  }
}
.bg-6fdd9d{
  background: #6fdd9d;
  &:hover{
    border: 1px solid #6fdd9d;
    color: #6fdd9d !important;
  }
  &:focus{
    border: 1px solid #6fdd9d;
    color: #6fdd9d !important;
  }
}
.bg-e36060{
  background: #e36060;
  &:hover{
    border: 1px solid #e36060;
    color: #e36060 !important;
  }
  &:focus{
    border: 1px solid #e36060;
    color: #e36060 !important;
  }
}
.bg-36a5d9{
  background: #36a5d9;
  &:hover{
    border: 1px solid #36a5d9;
    color: #36a5d9 !important;
  }
  &:focus{
    border: 1px solid #36a5d9;
    color: #36a5d9 !important;
  }
}
.bg-2dda8f{
  background: #2dda8f;
  &:hover{
    border: 1px solid #2dda8f;
    color: #2dda8f !important;
  }
  &:focus{
    border: 1px solid #2dda8f;
    color: #2dda8f !important;
  }
}
.bg-e57632{
  background: #e57632;
  &:hover{
    border: 1px solid #e57632;
    color: #e57632 !important;
  }
  &:focus{
    border: 1px solid #ed904c;
    color: #e57632 !important;
  }
}