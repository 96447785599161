/*BLOG GRID*/
.uni-blog-grid-blog{
  padding: 80px 0;
}
.blog-grid-item{
  padding-bottom: 30px;
  .uni-entry-top{
    .thumbnail-img{
      overflow: hidden;
      img{
        width: 100%;
        height: auto;
        transition:  all 0.5s;
        &:hover{
          transform: scale(1.1);
        }
      }
    }
  }
  .uni-entry-content{
    padding: 20px 20px 0 20px;
    background-color: #f4f4f4;
    .uni-entry-title{
      a{
        display:  block;
        font-family: 'Proxima Nova Th';
        font-size: 18px;
        font-weight: bold;
        font-style: italic;
        font-stretch: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: left;
        color: #22292d;
        text-transform: uppercase;
        transition:  all 0.5s;
        &:hover{
          color: #e57632;
        }
      }
    }
    .uni-divider{
      border-color: #e57632;
      margin-top:  20px;
    }
    .uni-entry-meta{
      padding: 15px 0;
      span{
        font-family: "Open Sans";
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 2.14;
        letter-spacing: normal;
        text-align: left;
        color: #666666;
        padding-right: 12px;
        text-transform: capitalize;
        display: inline-block;
        a{
          color: #666666;
          transition:  all 0.5s;
          &:hover{
            color: #e57632;
          }
        }
        i{
          color: #e57632;
        }
      }
    }
  }
}