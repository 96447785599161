/*TEAM*/
.uni-shortcode-team-body{
  background-color: #f4f4f4;
  padding: 80px 0;
}
.uni-team-default{
  .team-item{
    background: #fff;
    .team-item-img{
      overflow: hidden;
      img{
        width: 100%;
        height: auto;
        transition:  all 0.5s;
        &:hover{
          transform: scale(1.1);
        }
      }
    }
    .team-item-caption{
      padding: 20px;
      h3{
        a{
          display: block;
          font-family: 'Proxima Nova Th';
          font-size: 18px;
          font-weight: bold;
          font-style: italic;
          font-stretch: normal;
          line-height: 0.94;
          letter-spacing: normal;
          text-align: center;
          text-transform: uppercase;
          color: #22292d;
          margin: 0;
          transition:  all 0.5s;
          &:hover{
            color: #e57632;
            span{
              color: #22292d;
            }
          }
          span{
            color: #e57632;
            transition:  all 0.5s;
            margin-left: 5px;
          }
        }
      }
      .uni-divider{
        border-color: #e57632;
        margin: 20px 0;
      }
      p{
        font-family: "Open Sans";
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.21;
        letter-spacing: normal;
        text-align: center;
        color: #666666;
      }
    }
  }
  .owl-theme .owl-nav{
    visibility: hidden;
  }
  .owl-theme .owl-dots .owl-dot span{
    width: 25px;
    height: 6px;
    background-color: #e0e0e0;
    border-radius: 0;
    margin: 5px 3px;
    transform: skew(-40deg);
  }
  .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{
    background: #e57632;
  }
  .owl-theme .owl-nav.disabled+.owl-dots{
    margin-top: 60px;
  }
}
.uni-team-default-square{

}
.uni-team-default-circle{
  position: relative;
  display: flex;
  padding-top: 80px;
  .team-item{
    padding-top: 30px;
    .team-item-img{
      overflow: hidden;
      border-radius: 50%;
      margin: 0 35px;
      height: 200px;
    }
  }
}