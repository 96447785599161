.headerAvatar > img {
    border-radius: 50%;
    margin: 0 5px;
    max-height: 20px;
    max-width: 20px;
}
.floating-btn{
	width: 170px;
	height: 80px;
	background: #E57632;
	
	align-items:center;
	justify-content: center;
	text-decoration: none;	
	box-shadow: 2px 2px 5px rgba(0,0,0,0.25);
	/*position: fixed;
	display: flex;	
	z-index: 999;
	right: 2px;
	top: 10px;*/
	
	
    height: 44px;
    border-radius: 4px;
    background-color: #e57632;
    border: 1px solid #e57632;
    font-family: "Proxima Nova Th";
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff !important;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s; 
}
@media screen and (max-width: 1199px) and (min-width: 992px) {
	.floating-btn {
		width: 146px;
	}
	
}

.floating-btn:hover {
     background-color: #343c40;
	color:white;
}
	
.floating-btn:active{
	background: #E59000;
}

register-form, .login-form, .reset-password-form{
    margin: 50px 0;
}
.uni-underline{
    background: url("../../images/underline.png") no-repeat;
    background-position: center center;
    height: 16px;
  }
  .ui.secondary.pointing.menu .active.item a {
    color:orangered;
  }