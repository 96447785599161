/*SHOP CART*/
.uni-cart{
  .uni-cart-body{
    background: #f4f4f4;
  }
  .entry-content{
    padding: 100px 0;
  }
  .woocommerce{
    thead{
      border-bottom: 2px solid #e57632;
      tr{
        th{
          font-family: 'Proxima Nova Th';
          font-size: 14px;
          font-weight: bold;
          text-transform: uppercase;
          line-height: 2.14;
          text-align: left;
          color: #22292d;          
        }
      }
      
      .product-name{
        width: 45%;
      }

      .product-price{
        @media screen and (max-width:575px){
            display:none;
            }
      }
      .product-quantity{
        text-align:center;
        }
      .product-subtotal{
        text-align:right;
        
      }
    }
    tbody{
      tr{
        td{
          vertical-align: middle;
          border-top: 1px solid #E0E0E0;
        }
      }
      .product-remove{
        text-align: center;
        i{
          color: #e57632;
          font-size: 18px;
        }
      }
      .product-price{
        @media screen and (max-width:575px){
            display:none;
            }
      }
      .product-name{
        position: relative;
         @media screen and (min-width:576px){
            padding-left: 30px;
          }
          a.remove{
          position: absolute;
          left: 0;
          top: 15px;
            @media screen and (max-width:575px){
            top:0;
            }
          }
        .product-thumbnail{
          margin-right: 20px;
          display:  block;
          float: left;
          @media screen and (max-width:575px){
            display: inline-block; width: 100%;
          }
          a{
            display: inline-block;
            border-radius: 3px;
            background-color: #ffffff;
            border: solid 1px #e0e0e0;
            padding: 5px;
            &:hover{
              border-color: #e57632;
            }
            img{
              display: inline-block;
              width: 60px;
              height: 60px;
              overflow: hidden;
            }
          }
        }
        a{
          font-family: "Open Sans";
          font-size: 14px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: 60px;
          letter-spacing: normal;
          text-align: left;
          color: #22292d;
          transition: all 0.5s;
          @media screen and (max-width:991px){
            line-height: 28px;
          }
          &:hover{
            color: #e57632;
          }
        }
      }
      .product-price{
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        color: #666;
      }
      .product-subtotal{
        font-family: "Open Sans";
        font-size: 14px;
        font-weight: bold;
        color: #666;
        
            text-align:right;
        
      }
      .product-quantity{
        text-align:center;
        .quantity{
          input{
            font-family: 'Montserrat', sans-serif;
            font-size: 14px;
            font-weight: bold;
            color: #22292d;
            width: 75px;
            height: 36px;
            border-radius: 3px;
            border: solid 1px #E0E0E0;
            padding: 0 10px;
            text-align: center;
            @media screen and (max-width:575px){
            width: 50px;
              text-align: center;
            }
          }
        }
      }
    }
    tfoot{
      tr{
        td{
          padding: 20px 0;
          @media screen and (max-width: 991px) {
            padding: 30px 10px;
          }
        }
      }
      .actions{
        .coupon{
          float: left;
          @media screen and (max-width: 991px) {
            float: none;
          }
        }
        #coupon_code{
          font-family: 'Open Sans', sans-serif;
          font-size: 14px;
          text-align: left;
          width: 250px;
          height: 40px;
          padding: 0 14px;
          margin-right: 10px;
          border: solid 1px #e0e0e0;
          border-radius: 4px;
          @media screen and (max-width: 991px) {
            width: 100%;
            margin-bottom: 20px;
          }
        }
      }
      .button{
        font-family: 'Proxima Nova Th';
        font-size: 14px;
        font-weight: bold;
        line-height: 2.14;
        text-align: center;
        background: #e57632;
        border: 1px solid #e57632;
        color: #fff;
        border-radius: 3px;
        padding: 4px 26px;
        transition: all 0.5s;
        text-transform: uppercase;
        float: right;
        @media screen and (max-width: 991px) {
          width: 100%;
          margin-bottom: 20px;
        }
        &:hover{
          border-color: #e57632;
          background: transparent;
          color: #e57632;
        }
      }
    }
  }

  .cart-collaterals{
    padding-top: 40px;
    .cart_totals{
      h2{
        font-family: 'Proxima Nova Th';
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
        color: #404040;
        margin-bottom: 20px;
      }
    }
    table{
      width: 100%;
      border-top: 2px solid #e57632;
      th{
        font-size: 16px;
        font-weight: 500;
        line-height: 2.14;
        text-align: left;
        color: #22292d;
        width: 50%;
        padding: 10px 0;
        border-top:1px solid #e0e0e0;
      }
      tr{
        border-top:1px solid #e0e0e0;
        color: #666;
      }
      .order-total{
        border-bottom: 1px solid #e0e0e0;
        font-weight: bold;
      }
    }
    .wc-proceed-to-checkout{
      padding-top: 20px;
      a{
        &.button{
          display: block;
          font-family: 'Proxima Nova Th';
          font-size: 14px;
          font-weight: bold;
          line-height: 2.14;
          text-align: center;
          color: #ffffff;
          background: #e57632;
          border-radius: 3px;
          border: 1px solid #e57632;
          padding: 11px;
          text-transform: uppercase;
          transition:  all 0.5s;
          &:hover{
            background: #fff;
            color: #e57632;
          }
        }
      }
    }
  }
}