/*COMPARE*/
.uni-compare-body{
  padding: 80px 0;
  .uni-compare-category{
    background-color: #22292d;
    padding: 40px 30px 0 30px;
    h2{
      font-family: "Proxima Nova Th";
      font-size: 30px;
      font-weight: bold;
      font-style: italic;
      font-stretch: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: left;
      color: #e57632;
      text-transform:  uppercase;
      span{
        color: #fff;
      }
    }
    .uni-divider{
      border-color: #e57632;
    }
    ul{
      padding-top:  100px;
      li{
        font-family: "Open Sans";
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 3.57;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;
        text-transform:  uppercase;
        border-bottom: 1px solid #374045;
        &:last-child{
          border-bottom:  none;
        }
      }
    }
  }
  .uni-compare-details{
    .uni-compare-details-head{
     position:  relative;
      .uni-compare-details-img{
        width: 100%;
        height: 180px;
        overflow: hidden;
        img{
          width: 100%;
          height: auto;
        }
      }
      .capation{
        position:  absolute;
        top: 0;
        width: 100%;
        height: 100%;
        .close{
          width: 36px;
          height: 36px;
          background-color: rgba(0, 0, 0, 0.5);
          font-family: FontAwesome;
          font-size: 14px;
          opacity: 1;
          border-radius:  0;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.21;
          letter-spacing: normal;
          text-align: center;
          color: #ffffff;
          position:  absolute;
          top:0;
          right: 0;
          transition:  all 0.5s;
          &:hover{
            color: #fff;
            background-color: #e57632;
          }
        }
        p{
          font-family: "proxima_nova_altbold";
          font-size: 20px;
          font-weight: Bold;
          font-style: italic;
          font-stretch: normal;
          line-height: 40px;
          letter-spacing: normal;
          text-align: center;
          color: #ffffff;
          background-color: #e57632;
          padding:  0 20px;
          position:  absolute;
          bottom:20px;
          right: 0;
        }
      }
    }
    h2{
      margin-top: 10px;
      a{
        display:  block;
        font-family: "Proxima Nova Th";
        font-size: 18px;
        font-weight: bold;
        font-style: italic;
        font-stretch: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: left;
        color: #333;
        text-transform:  uppercase;
        transition:  all 0.5s;
        &:hover{
          color: #e57632;
        }
      }
    }
    .uni-divider{
      border-color: #e57632;
    }
    ul{
      li{
        font-family: "Open Sans";
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 3.57;
        letter-spacing: normal;
        text-align: left;
        color: #666;
        text-transform:  uppercase;
        border-bottom: 1px solid #E0E0E0;
      }
    }
  }
  .uni-compare-add{
    .uni-compare-details-head{
      position:  relative;
      .uni-compare-details-img{
        width: 100%;
        height: 180px;
        overflow: hidden;
        img{
          width: 100%;
          height: auto;
        }
      }
    }
    h2{
      margin-top: 10px;
      a{
        display:  block;
        font-family: "Proxima Nova Th";
        font-size: 18px;
        font-weight: bold;
        font-style: italic;
        font-stretch: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: left;
        color: #aeaeae;
        text-transform:  uppercase;
        transition:  all 0.5s;
        &:hover{
          color: #e57632;
        }
      }
    }
    .uni-divider{
      border-color: #e57632;
    }
    ul{
      li{
        font-family: "Open Sans";
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 3.57;
        letter-spacing: normal;
        text-align: left;
        color: #666;
        text-transform:  uppercase;
        border-bottom: 1px solid #E0E0E0;
      }
    }
  }
}